import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleManifiesto } from './DetalleManifiesto';

import { RequestPage } from '../../../api/request';
import { getManifiesto, searchManifiestoCli, searchManifiestoDoc, searchManifiestoDates } from '../../../api/requestManifiesto';

import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { DateRangePicker, Stack } from 'rsuite';

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const ConsultaManifiesto = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // States para los datos
  const [Manifiesto, setManifiesto] = useState([]);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState([]);

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de busqueda
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [manifiestoFound, setManifiestoFound] = useState([]);
  const [manifiestoFoundCliente, setManifiestoFoundCliente] = useState([]);
  const [manifiestoFoundFecha, setManifiestoFoundFecha] = useState([]);

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  const predefinedRanges = [
    {
      label: 'Hoy',
      value: [new Date(), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Ayer',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'bottom'
    },
    {
      label: 'Esta semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'bottom'
    },
    {
      label: 'Últimos 7 días',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Últimos 30 días',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes actual',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes anterior',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Este año',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    },
    {
      label: 'Desde el comienzo',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Última semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Próxima semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];

  const loadManifiestos = async () => {
    setLoading(true)

    var res;

    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      res = await getManifiesto(user.CodVendedor);
    } else if (user.CodSupervisor === null || user.CodGerente !== null) {
      res = await getManifiesto('none');
    }


    if (res.data.length > 0) {
      String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
      };

      setManifiesto(res.data)
      setPage(res.current_page)
      setTotalPages(res.last_page)
      setUrlPage(res.first_page_url)

      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }

  const loadPage = async (pageData) => {
    setLoading(true)
    const manifiestoData = await RequestPage(pageData);

    setManifiesto(manifiestoData.data)
    setPage(manifiestoData.current_page)
    setTotalPages(manifiestoData.last_page)
    setUrlPage(manifiestoData.first_page_url)

    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor))
    } else if (user.CodSupervisor === null || user.CodGerente !== null) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=none"))
    }
  }

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadManifiestos();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await searchManifiestoCli(busqueda, user.CodVendedor);
      // BUSQUEDA POR CLIENTE
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return;
      } else {
        setFoundByCliente(true)
        setManifiestoFoundCliente(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    } else if (searchbarDoc === 'd-block') {
      // BUSQUEDA POR DOCUMENTO
      if (!busqueda) {
        setFound(false)
        return loadManifiestos();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await searchManifiestoDoc(busqueda.toUpperCase(), user.CodVendedor);
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        return;
      } else {
        setFound(true)
        setManifiestoFound(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchDate != 'd-block') {
      setFoundByFecha(false)
    }
  };

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let inicio = date.slice(2, 12) + " " + date.slice(14, 21)
    let fin = date.slice(29, 39) + " " + date.slice(41, 48)

    const res = await searchManifiestoDates(inicio, fin, user.CodVendedor)
    // console.log(res)
    if (res.data.length === 0) {
      setFound(false)
      setFoundByFecha(false)
    }
    else {
      setFound(false)
      setFoundByFecha(true)
      setManifiestoFoundFecha(res.data)
    }
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Doc':
        setSearchbarDoc('d-block')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
      case 'Cliente':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-block')
        setSearchDate('d-none')
        break;
      case 'Fecha':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-block')
        break;

      case 'Limpiar':
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        // setFoundByFecha(false)
        onSearch('')
        break;

      default:
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  useEffect(() => {
    loadManifiestos();
  }, []);

  return (
    <>
      <Layout>
        {/* Cuerpo de la página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-documentos"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Manifiestos</h4>
              </div>

              <h5 className="text-center mt-2 mb-4 belmeny-text"><i>
                {
                  (user.CodGerente !== null) ?
                    <>
                      Supervisor / Gerente - {user.Nombre} - {user.CodVendedor}
                    </> :
                    <>
                      Vendedor - {user.Nombre} - {user.CodVendedor}
                    </>
                }
              </i></h5>

              {/* Desktop */}
              <div className="container-fluid d-md-block d-none mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>

                <div className="rounded-0 my-2">
                  <table className='table table-bordered table-hover border-belmeny '>
                    <thead className='bg-belmeny text-light text-center'>
                      <tr>
                        <th>Manifiesto</th>
                        <th>Estado</th>
                        <th>Fecha</th>
                        <th className='w-25'>Empresa de Transporte</th>
                        <th>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (found) ?
                          <>
                            {manifiestoFound.map((item) => <DetalleManifiesto item={item} found={found} />)}
                          </> :
                          (foundByCliente) ?
                            <>
                              {manifiestoFoundCliente.map((item) => <DetalleManifiesto item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                            </> :
                            (foundByFecha) ?
                              <>
                                {manifiestoFoundFecha.map((item) => <DetalleManifiesto item={item} found={found} foundFecha={foundByFecha} />)}
                              </> :
                              <>
                                {Manifiesto.map((item) => <DetalleManifiesto item={item} found={found} />)}
                              </>
                      }
                    </tbody>
                  </table>
                </div>
                {
                  (foundByFecha) ?
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </> :
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
              </div>

              {/* Mobile */}
              <div className="container-fluid d-block d-sm-none">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>

                {
                  (found) ?
                    <>
                      {manifiestoFound.map((item) => <DetalleManifiesto item={item} found={found} />)}
                    </> :
                    (foundByCliente) ?
                      <>
                        {manifiestoFoundCliente.map((item) => <DetalleManifiesto item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                      </> :
                      (foundByFecha) ?
                        <>
                          {manifiestoFoundFecha.map((item) => <DetalleManifiesto item={item} found={found} foundFecha={foundByFecha} />)}
                        </> :
                        <>
                          {Manifiesto.map((item) => <DetalleManifiesto item={item} found={found} />)}
                        </>
                }
                {
                  (foundByFecha) ?
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </> :
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }

                <div className="division bg-belmeny my-3"></div>
              </div>
            </>
        }
      </Layout>
    </>
  )
}
