
import React, { useState, useEffect } from 'react'

import AuthUser from '../../../components/AuthUser';
import { ProductosFactura } from './ProductosFactura';
import { ProductosFacturaMobile } from './ProductosFacturaMobile';
import { FacturaPDF } from '../../PDF/FacturaPDF';

import { getClienteRIF } from '../../../api/requestClientes';
import { RequestPage, getVendedor } from '../../../api/request';
import { getProductosFactura, getCantidadProductos } from '../../../api/requestFacturas';

import Pagination from 'react-responsive-pagination';
import { Dropdown, ButtonToolbar } from 'rsuite';
import { Modal } from 'react-bootstrap';
import { RiCloseCircleFill, RiPrinterFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";

import brokenImg from '../../../assets/img/broken-image-general.png';

export const DetalleFactura = (props) => {

  var Facturas = props.item

  // States de datos
  const [cliente, setCliente] = useState('');

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [vendedor, setVendedor] = useState('')
  const [codigoVendedor, setCodigoVendedor] = useState('')
  const [productos, setProductos] = useState([]);
  const [cantProds, setCantProds] = useState(0);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadProductos();
  }

  const loadProductos = async () => {
    setLoading(true)

    const [resProductos, resClient, resCantProds ] = await Promise.all([getProductosFactura(Facturas.Documento, Facturas.CodigoVendedor), getClienteRIF(Facturas.codcliente), getCantidadProductos(Facturas.Documento)])

    const productos = await resProductos;
    const cliente = await resClient;
    const vend = await getVendedor(cliente[0].Vendedor)
    const cantProducts = await resCantProds

    if (productos.data[0].CodigoVendedor === 'V1') {
      setVendedor('OFICINA')
    } else {
      setVendedor(vend[0].Nombre)
    }

    setCodigoVendedor(productos.data[0].CodigoVendedor)
    setCliente(productos.data[0].Cliente)
    setCantProds(cantProducts[0].CantProductos)
    setPage(productos.meta.current_page)
    setTotalPages(productos.meta.last_page)
    setUrlPage(productos.links.first)

    setProductos(productos.data)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setPage(productoData.meta.current_page)
    setTotalPages(productoData.meta.last_page)
    setUrlPage(productoData.links.first)

    setProductos(productoData.data)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    loadPage(urlPage.replace(/.$/, page))
  }

  return (
    <>
      <tr className='d-none-table'>
        <td className='text-danger fw-bold text-center'>
          {Facturas.Documento}
        </td>
        <td className='w-50'>
          {Facturas.nombrecli}
        </td>
        {(user.CodGerente !== null) ? <td className='d-md-block-table d-none-table fw-bold belmeny-text text-center'>{Facturas.Nombre}</td> : null}
        <td className='d-md-block-table d-none-table text-success fw-bold text-center'>
          ${Facturas.TotalFact}
        </td>
        <td className='text-center'>
          {new Date(Facturas.FechaDocumento).toLocaleDateString('es-MX')}
        </td>
        <td className="text-center">
          <p className="btn btn-success btn-sm mt-2 mb-2 w-100" onClick={() => handleShow()}>Ver detalles</p>
          <FacturaPDF user={user} Factura={Facturas} />
        </td>
      </tr>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title belmeny-text">{Facturas.nombrecli.toUpperCase()}</h5>
              </div>
              <div className="col-3">
                <ButtonToolbar >
                  <Dropdown title={<SlOptionsVertical />} activeKey="e-2" className='border-belmeny'>
                    <Dropdown.Item eventKey="details"><AiFillEye className='belmeny-text fs-2' onClick={() => handleShow()} /></Dropdown.Item>
                    <Dropdown.Item eventKey="report"><FacturaPDF user={user} Factura={Facturas} /></Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">{Facturas.Documento}</h6>
            {(user.CodGerente !== null) ? <h6 className="card-subtitle mb-2 belmeny-text fst-italic">{Facturas.Nombre}</h6> : null}
            <h6>Monto: <span className="text-success fw-bold">${Facturas.TotalFact}</span></h6>
            <h6 className='my-1'>Fecha: {new Date(Facturas.FechaDocumento).toLocaleDateString('es-MX')}</h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información de la factura: {Facturas.Documento}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">

            {/* Div for desktop version */}
            <div className='d-none d-md-block d-lg-block'>

              <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Vendedor Encargado: {vendedor}</h3>

              <div className="row badge-vendedor rounded-pill">
                <div className="col">
                  <h3 className='text-start ms-3'>RIF: {Facturas.codcliente}</h3>
                </div>
                <div className="col">
                  <h3 className='text-end me-4'>{cliente}</h3>
                </div>
              </div>

              <div className="container mt-3">
                <div className="row">
                  <div className="col">
                    <h4><b>Base Imponible:</b> <span className="text-success">${Facturas.BaseImponible.toFixed(2)}</span></h4>
                    <h4><b>Flete:</b> <span className="text-success">${Facturas.Flete.toFixed(2)}</span></h4>
                    <h4><b>Tasa IVA:</b> <span className="text-success">{Facturas.TasaIVA}%</span></h4>
                    {
                      (Facturas.Descuento > 0) ?
                        <>
                          <h4><b>Descuento:</b> <span className="text-success">${Facturas.Descuento.toFixed(2)}</span></h4>
                        </> :
                        <>
                          <h4><b>Descuento:</b> <span className="text-success">$0.00</span></h4>
                        </>
                    }
                    <h4><b>Monto Total:</b> <span className="text-success">${Facturas.TotalFact.toFixed(2)}</span></h4>
                  </div>
                  <div className="col">
                    <h4><b>Fecha de expedición: </b>{new Date(Facturas.FechaDocumento).toLocaleDateString('es-MX')}</h4>
                    <h4><b>Fecha de vencimiento: </b>{new Date(Facturas.FechaVencimiento).toLocaleDateString('es-MX')}</h4>
                    {
                      (Facturas.Descuento > 0) ?
                        <>
                          <h4><b>Días de crédito:</b> 0 día(s)</h4>
                        </> :
                        <>
                          <h4><b>Días de crédito:</b> {Facturas.DiasCredito} día(s)</h4>
                        </>
                    }
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col">
                    <h4><b>Total de Productos:</b> <span className="text-success">{cantProds}</span></h4>
                  </div>
                </div>
              </div>

              <h3 className='bg-belmeny m-auto w-50 rounded-pill py-2 text-center text-light mt-3'>Lista de productos</h3>
              {
                (loading) ?
                  <>
                    <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                      <span className="loader-documentos"></span>
                    </div>
                  </> :
                  <>
                    <div className="my-3">
                      <table className='table table-bordered table-hover border-belmeny '>
                        <thead className='bg-belmeny text-light text-center'>
                          <tr>
                            <th style={{ width: '10%' }}>Imagén</th>
                            <th>Código</th>
                            <th>Producto</th>
                            <th>Precio Unitario</th>
                            <th>Cantidad</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productos.map((item) => <ProductosFactura item={item} key={item.Codigo} />)}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      total={totalPages}
                      current={currentPage}
                      onPageChange={page => handlePageChange(page)}
                    />

                    <div className="row mt-3">
                      <div className="col">
                        <h4>Base Imponible: </h4>
                        <h2 className='text-end pe-5 fs-1 text-success'>${Facturas.BaseImponible.toFixed(2)}</h2>
                      </div>
                      <div className="col">
                        <h4>Flete: </h4>
                        <h2 className='text-end pe-5 fs-1 text-success'>${Facturas.Flete.toFixed(2)}</h2>
                      </div>
                      <div className="col">
                        <h4>Tasa IVA: </h4>
                        <h2 className='text-end pe-5 fs-1 text-success'>{Facturas.TasaIVA}%</h2>
                      </div>
                      {
                        (Facturas.Descuento > 0) ?
                          <>
                            <div className="col">
                              <h4>Descuento: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${Facturas.Descuento.toFixed(2)}</h2>
                            </div>
                          </> :
                          <>
                            <div className="col">
                              <h4>Descuento: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>$0.00</h2>
                            </div>
                          </>
                      }

                      <div className="col">
                        <h4>Total Final: </h4>
                        <h2 className='text-end pe-5 fs-1 text-success'>${Facturas.TotalFact.toFixed(2)}</h2>
                      </div>
                    </div>
                  </>
              }
            </div>

            {/* Div for mobile version */}
            <div className='d-sm-block d-md-none'>

              <h5 className="bg-belmeny text-light rounded-pill text-center m-auto mb-3">Vendedor Encargado: {vendedor}</h5>

              <div className="badge-vendedor rounded-pill">
                <div className="row">
                  <h5 className='text-center ms-3 fst-italic'>RIF: {Facturas.codcliente}</h5>
                </div>
                <div className="row">
                  <h5 className='text-center me-4'>{cliente}</h5>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col">
                  <h6><b>Base Imponible:</b> <span className="text-success">${Facturas.BaseImponible.toFixed(2)}</span></h6>
                  <h6><b>Flete:</b> <span className="text-success">${Facturas.Flete}</span></h6>
                  <h6><b>Tasa IVA:</b> <span className="text-success">{Facturas.TasaIVA}%</span></h6>
                  {
                    (Facturas.Descuento > 0) ?
                      <>
                        <h6><b>Descuento:</b> <span className="text-success">${Facturas.Descuento.toFixed(2)}</span></h6>
                      </> :
                      <>
                        <h6><b>Descuento:</b> $0</h6>
                      </>
                  }
                  <h6><b>Monto Total:</b> <span className="text-success">${Facturas.TotalFact.toFixed(2)}</span></h6>
                </div>
                <div className="col">
                  <h6><b>Fecha de expedición:</b> {new Date(Facturas.FechaDocumento).toLocaleDateString('es-MX')}</h6>
                  <h6><b>Fecha de vencimiento:</b> {new Date(Facturas.FechaVencimiento).toLocaleDateString('es-MX')}</h6>
                  {
                    (Facturas.Descuento > 0) ?
                      <>
                        <h6><b>Días de crédito:</b> 0</h6>
                      </> :
                      <>
                        <h6><b>Días de crédito:</b> {Facturas.DiasCredito} días</h6>
                      </>
                  }
                </div>

                <h4 className='bg-belmeny m-auto rounded-pill py-2 text-center text-light mt-4'>Lista de productos</h4>

                {
                  (loading) ?
                    <>
                      <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <span className="loader-documentos"></span>
                      </div>
                    </> :
                    <>
                      <div className="my-3">
                        {productos.map((item) => <ProductosFacturaMobile item={item} key={item.Codigo} />)}
                      </div>
                      <div className="row">
                        <div className="col">
                          <Pagination
                            total={totalPages}
                            current={currentPage}
                            onPageChange={page => handlePageChange(page)}
                          />
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
