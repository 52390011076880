import React from 'react'

import { formatNumber } from '../../utils/formats'

export const CorteSemanalSupervisor = ({ startPastWeek, endPastWeek, startCurrentWeek, endCurrentWeek, zonasSupervisor, ventasSemanales, ventasGralZona, ventasSemanalesOtrasZonas }) => {
  return (
    <div>
      {
        (zonasSupervisor.length > 1) ?
          <div className="row">
            <div className="col d-flex align-middle justify-content-center">
              <div className="card text-center drop-shadow-card border-0 mt-0">
                <div className="card-body">
                  <h4 className="card-title fst-italic belmeny-text">{zonasSupervisor[0].Sector}</h4>
                  <h5 className="card-title fs-6">Semana del {startPastWeek} al {endPastWeek} VS Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                  <div className="row">
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[0].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[0].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex align-middle justify-content-center">
              <div className="card text-center drop-shadow-card border-0 mt-0">
                <div className="card-body">
                  <h4 className="card-title fst-italic belmeny-text">{zonasSupervisor[1].Sector}</h4>
                  <h5 className="card-title fs-6">Semana del {startPastWeek} al {endPastWeek} VS Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                  <div className="row">
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[1].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[1].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[1].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[1].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[1].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[1].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex align-middle justify-content-center">
              <div className="card text-center drop-shadow-card border-0 mt-0">
                <div className="card-body">
                  <h4 className="card-title fst-italic belmeny-text">{zonasSupervisor[2].Sector}</h4>
                  <h5 className="card-title fs-6">Semana del {startPastWeek} al {endPastWeek} VS Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                  <div className="row">
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[2].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[2].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[2].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[2].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[2].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[2].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="row">
            <div className="col d-flex align-middle justify-content-center">
              <div className="card w-50 text-center drop-shadow-card border-0 mt-0">
                <div className="card-body">
                  <h4 className="card-title">{zonasSupervisor[0].Sector}</h4>
                  <h5 className="card-title fs-6">Semana del {startPastWeek} al {endPastWeek} VS Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                  <div className="row">
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaPasada.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[0].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                    <div className="col">
                      <h6 className="card-text">
                        {formatNumber((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2))} USD -
                        <span className="text-success ms-1">
                          {formatNumber((((ventasSemanales.semanaActual.find((item) => item.Sector === zonasSupervisor[0].Sector).ventasSemanal).toFixed(2) * 100) / ventasGralZona.find((item) => item.Sector === zonasSupervisor[0].Sector).meta_zona).toFixed(2))}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }

      <div className="container">
        <div className="row">
          {/* VENTAS SEMANALES */}
          <h4 className="text-center belmeny-text mb-3">Otras zonas</h4>
          <div className="col drop-shadow-card rounded h-100">
            <div className="row">
              <div className="bg-white text-center drop-shadow-card border-0 mb-2 rounded py-2">
                <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[0].Sector}</h4>
                <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                <h6 className="card-text">Ventas:
                  <span className="ps-1 text-success">
                    {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[0].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[0].ventasSemanal * 0.031)).toFixed(2))} USD
                  </span>
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="bg-white text-center drop-shadow-card border-0 mb-2 rounded py-2">
                <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[1].Sector}</h4>
                <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                <h6 className="card-text">Ventas:
                  <span className="ps-1 text-success">
                    {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[1].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[1].ventasSemanal * 0.031)).toFixed(2))} USD
                  </span>
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="bg-white text-center drop-shadow-card border-0 mb-2 rounded py-2">
                <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[2].Sector}</h4>
                <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                <h6 className="card-text">Ventas:
                  <span className="ps-1 text-success">
                    {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[2].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[2].ventasSemanal * 0.031)).toFixed(2))} USD
                  </span>
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="bg-white text-center drop-shadow-card border-0 mb-2 rounded py-2">
                <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[3].Sector}</h4>
                <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                <h6 className="card-text">Ventas:
                  <span className="ps-1 text-success">
                    {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[3].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[3].ventasSemanal * 0.031)).toFixed(2))} USD
                  </span>
                </h6>
              </div>
            </div>
            {
              (ventasSemanalesOtrasZonas.semanaActual.length >= 4) ?
                <>
                  <div className="row">
                    <div className="bg-white text-center drop-shadow-card border-0 mb-2 rounded py-2">
                      <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[4].Sector}</h4>
                      <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                      <h6 className="card-text">Ventas:
                        <span className="ps-1 text-success">
                          {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[4].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[4].ventasSemanal * 0.031)).toFixed(2))} USD
                        </span>
                      </h6>
                    </div>
                  </div>
                  {
                    (ventasSemanalesOtrasZonas.semanaActual.length > 5) ?
                      <>
                        <div className="row">
                          <div className="bg-white text-center drop-shadow-card border-0 rounded py-2">
                            <h4 className="card-title fst-italic belmeny-text">{ventasSemanalesOtrasZonas.semanaActual[5].Sector}</h4>
                            <h5 className="card-title fs-6">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
                            <h6 className="card-text">Ventas:
                              <span className="ps-1 text-success">
                                {formatNumber(((ventasSemanalesOtrasZonas.semanaActual[5].ventasSemanal) - (ventasSemanalesOtrasZonas.semanaActual[5].ventasSemanal * 0.031)).toFixed(2))} USD
                              </span>
                            </h6>
                          </div>
                        </div>
                      </>
                      : null
                  }
                </>
                : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}
