import React, { useState } from 'react'

import Chart from "react-apexcharts";

export const RankingVendedores = ({ top10Chart }) => {
  return (
    <>
      <div className="row">
        <div className="col bg-white rounded drop-shadow-card">
          <h4 className="belmeny-text text-center fst-italic mt-4">Ranking top 10 vendedores del mes</h4>
          <Chart
            options={top10Chart.options}
            series={top10Chart.series}
            type="bar"
            width="100%"
            height="450px"
          />
        </div>
      </div>
    </>
  )
}
