import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import userIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'

import { getZonasSupervisor, getEstimaciones, getTotalFPC } from '../../api/requestSupervisor';

import Chart from "react-apexcharts";

import { startOfMonth, endOfMonth, addMonths, endOfDay, startOfWeek, endOfWeek, subWeeks, format, set } from 'date-fns';
import moment from 'moment/moment';
import { GiBreakingChain } from 'react-icons/gi';

export const Estimaciones = () => {

  useEffect(() => {
    loadZonasSupervisor()
    handleMonthLoader('1mes')
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('')
  const [loadingText, setLoadingText] = useState('estadisticas...');
  const [whichMonthLoad, setWhichMonthLoad] = useState('1mes');
  const [zonaSelected, setZonaSelected] = useState('Ningúna...');
  const [mesSelected, setMesSelected] = useState('Ningúno...');
  const [totalMeta, setTotalMeta] = useState(0);
  const [montoProductos, setMontoProductos] = useState(0);
  const [montoFacturado, setMontoFacturado] = useState(0);
  const [montoCancelado, setMontoCancelado] = useState(0);
  const [montoPendiente, setMontoPendiente] = useState(0);
  const [facturadoPorcentaje, setFacturadoPorcentaje] = useState(0);
  const [canceladoPorcentaje, setCanceladoPorcentaje] = useState(0);
  const [pendientePorcentaje, setPendientePorcentaje] = useState(0);
  const [multipleTops, setMultipleTops] = useState(false);
  const [estimacionGenerada, setEstimacionGenerada] = useState(false);
  const [montoEstimaciones, setMontoEstimaciones] = useState([]);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [ventasProductos, setVentasProductos] = useState([]);
  const [chartEstimaciones, setChartEstimaciones] = useState({});
  // const [barEstiVend, setBarEstiVend] = useState({});
  const [barChartVentas, setBarChartVentas] = useState({})
  const [topDataMetas, setTopDataMetas] = useState({});
  const [topDataMetas2, setTopDataMetas2] = useState({});
  const [topDataMetas3, setTopDataMetas3] = useState({});

  // Nombres de los meses
  const [threeMonthsName, setThreeMonthsName] = useState(startOfMonth(addMonths(new Date(), -3)).toLocaleString("es-MX", { month: "long" }));
  const [twoMonthsName, setTwoMonthsName] = useState(startOfMonth(addMonths(new Date(), -2)).toLocaleString("es-MX", { month: "long" }));
  const [oneMonthName, setOneMonthName] = useState(startOfMonth(addMonths(new Date(), -1)).toLocaleString("es-MX", { month: "long" }));

  //Fecha inicio y fin de los meses
  const [start3Months, setStart3Months] = useState(new Date(startOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10))
  const [end3Months, setEnd3Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10)), 'yyyy-MM-dd 23:59:59'))
  const [start2Months, setStart2Months] = useState(new Date(startOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))
  const [end2Months, setEnd2Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10)), 'yyyy-MM-dd 23:59:59'))
  const [start1Month, setStart1Month] = useState(new Date(startOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10))
  const [end1Month, setEnd1Month] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10)), 'yyyy-MM-dd 23:59:59'));
  const [startActualMonth, setStartActualMonth] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))
  const [endActualMonth, setEndActualMonth] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))

  function sortByMonth(arr) {
    var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    arr.sort(function (a, b) {
      return months.indexOf(a.mes)
        - months.indexOf(b.mes);
    });
  }

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const handleMonthLoader = (opt) => {
    switch (opt) {
      case '1mes':
        setWhichMonthLoad('1mes')
        // loadHistorico1erMes()
        break;

      case '2mes':
        setWhichMonthLoad('2mes')
        // loadHistorico2doMes()
        break;

      case '3mes':
        setWhichMonthLoad('3mes')
        // loadHistorico3erMes()
        break;

      default:
        // loadHistorico1erMes()
        break;
    }
  }

  const loadZonasSupervisor = async () => {
    setLoading(true)
    setLoadingText(`datos iniciales`)
    const [resZonasSup] = await Promise.all([getZonasSupervisor(user.CodGerente)])
    setZonasSupervisor(await resZonasSup);
    setLoading(false)
  }

  const generateEstimaciones = async (zonaSeleccionada, mesSeleccionado) => {
    let resEstimaciones;
    let fpc;
    setLoading(true)
    setLoadingText(`estimaciones de ${mesSeleccionado} para la zona ${zonaSeleccionada}...`)

    if (mesSeleccionado === threeMonthsName) {
      resEstimaciones = await getEstimaciones(zonaSeleccionada, start3Months + ' 00:00:00', end3Months)
      fpc = await getTotalFPC(start3Months + ' 00:00:00', end3Months)
    } else if (mesSeleccionado === twoMonthsName) {
      resEstimaciones = await getEstimaciones(zonaSeleccionada, start2Months + ' 00:00:00', end2Months)
      fpc = await getTotalFPC(start2Months + ' 00:00:00', end2Months)
    } else if (mesSeleccionado === oneMonthName) {
      resEstimaciones = await getEstimaciones(zonaSeleccionada, start1Month + ' 00:00:00', end1Month)
      fpc = await getTotalFPC(start1Month + ' 00:00:00', end1Month)
    } else {
      resEstimaciones = await getEstimaciones(zonaSeleccionada, start1Month + ' 00:00:00', end1Month)
      fpc = await getTotalFPC(start1Month + ' 00:00:00', end1Month)
    }

    if (resEstimaciones.length > 0) {
      setEstimacionGenerada(true)
      setMontoEstimaciones(resEstimaciones)
      setMontoFacturado(fpc.Facturado)
      setMontoCancelado(fpc.CanceladoMasAbonado)
      setMontoPendiente(fpc.Pendiente)
      setChartEstimaciones({
        options: {
          labels: resEstimaciones.map((Vendedor) => `${Vendedor.Nombre}`),
          legend: {
            show: true,
            position: "bottom",
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
              width: '50px',
              stacked: true,
              stackType: "100%",

            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000']
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['#fff']
          },
          colors: ['#3268a9', '#96bc47', '#d94440'],
        },
        series: [{
          name: 'Facturado',
          data: resEstimaciones.map((Vendedor) => `${((Vendedor.Facturado / fpc.Facturado) * 100).toFixed(2)}%`)
        }, {
          name: 'Cancelado',
          data: resEstimaciones.map((Vendedor) => `${((Vendedor.CanceladoMasAbonado / fpc.Facturado) * 100).toFixed(2)}%`)
        }, {
          name: 'Pendiente',
          data: resEstimaciones.map((Vendedor) => `${((Vendedor.Pendiente / fpc.Facturado) * 100).toFixed(2)}%`)
        }]
      })
      let facturadoSum = 0
      let canceladoSum = 0 
      let pendienteSum = 0
      
      for (let i = 0; i < resEstimaciones.length; i++) {
        facturadoSum += ((resEstimaciones[i].Facturado * 100) / fpc.Facturado)
        canceladoSum += ((resEstimaciones[i].CanceladoMasAbonado * 100) / fpc.Facturado)
        pendienteSum += ((resEstimaciones[i].Pendiente * 100) / fpc.Facturado)
      }
      setFacturadoPorcentaje(facturadoSum)
      setCanceladoPorcentaje(canceladoSum)
      setPendientePorcentaje(pendienteSum)
      setLoading(false)
    } else {
      setEstimacionGenerada(false)
      setLoading(false)
    }
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div className="text-center">
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {loadingText}</span>
                    </div>
                  </div>
                </div>
              </> :
              <>

                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  {/* <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow' /> */}
                  <br />
                  <img src={notFoundImg} className="mx-auto w-100" alt="No se ha podido cargar la imagen..." />
                </div>

                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row">
                      <div className="col">
                        <div className="belmeny-text">
                          <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                          <h3>{user.Nombre}</h3>
                          <h5><i>{username}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow-sm' />
                      </div>
                    </div>

                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Estimaciones Cobranzas</h4>
                    </div>

                    <div className="mt-5 mb-3">
                      <h5 className="belmeny-text fst-italic">Seleccione una zona:</h5>
                    </div>
                    <div className="row">
                      <div className="row">
                        {
                          zonasSupervisor.map((zona) =>
                            <div className='col'>
                              <button className='buttonMonths w-100' onClick={(e) => setZonaSelected(zona.Sector)}><span>{zona.Sector}</span></button>
                            </div>
                          )
                        }
                      </div>
                    </div>

                    <div className="mt-4 mb-3">
                      <h5 className="belmeny-text fst-italic">Seleccione un mes:</h5>
                    </div>
                    <div className="row mb-4">
                      <div className="col-1 me-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(threeMonthsName)}><span className="text-capitalize">{threeMonthsName}</span></button>
                      </div>
                      <div className="col-1 mx-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(twoMonthsName)}><span className="text-capitalize">{twoMonthsName}</span></button>
                      </div>
                      <div className="col-1 ms-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(oneMonthName)}><span className="text-capitalize">{oneMonthName}</span></button>
                      </div>
                    </div>

                    <div className="row mt-4 mb-3">
                      <div className="col-3">
                        <div className="row">
                          <div className="col">
                            <h5 className="belmeny-text">Zona Seleccionada: {zonaSelected}</h5>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col">
                            <h5 className="belmeny-text">Mes Seleccionado: <span className="text-capitalize">{mesSelected}</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      (zonaSelected !== 'Ningúna...' && mesSelected !== 'Ningúno...') ?
                        <div className="row mb-5">
                          <div className="col">
                            <div className='col'>
                              <button className='buttonMonths' onClick={(e) => generateEstimaciones(zonaSelected, mesSelected)}><span>Generar Estimaciones</span></button>
                            </div>
                          </div>
                        </div> : null
                    }

                    {/* Chart de ventas x marca */}
                    {
                      (estimacionGenerada) ?
                        <>
                          <div className="row">
                            <div className="col">
                              <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                                <h4 className="belmeny-text text-center mx-auto fst-italic">Estimaciones del mes: <span className="text-capitalize">{mesSelected}</span> <br /> Zona: {zonaSelected}</h4>
                                <div className="row my-3">
                                  <div className="col"></div>
                                  <div className="col-2"><h5 className="fw-bold belmeny-text">Facturado: <span style={{ color: '#3268a9' }}>${montoFacturado}</span></h5></div>
                                  <div className="col-2"><h5 className="fw-bold belmeny-text">Pendiente: <span style={{ color: '#d94440' }}>${montoPendiente}</span></h5></div>
                                  <div className="col-2"><h5 className="fw-bold belmeny-text">Cancelado: <span style={{ color: '#96bc47' }}>${montoCancelado}</span></h5></div>
                                  <div className="col"></div>
                                </div>
                                <div className="container-fluid mx-auto row bg-lighten mt-3 py-3">
                                  <div className="col-2">
                                    <h5 className="text-uppercase belmeny-text">VENDEDOR</h5>
                                  </div>
                                  <div className="col">
                                    <h5 className="text-uppercase belmeny-text text-center">TOTAL FACTURADO</h5>
                                  </div>
                                  <div className="col">
                                    <h5 className="text-uppercase belmeny-text text-center">TOTAL FAC/NE PENDIENTES</h5>
                                  </div>
                                  <div className="col">
                                    <h5 className="text-uppercase belmeny-text text-center">TOTAL FAC/NE CANCELADAS</h5>
                                  </div>
                                </div>
                                {
                                  montoEstimaciones.map((Vendedor) =>
                                    <div className="row mt-3 container-fluid mx-auto pb-4 fw-bold">
                                      <div className="col-2 belmeny-text">{Vendedor.CodVendedor} - {Vendedor.Nombre}</div>
                                      <div className="col text-center" style={{ color: '#3268a9' }}>
                                        ${Vendedor.Facturado} - {((Vendedor.Facturado / montoFacturado) * 100).toFixed(2)}%
                                      </div>
                                      <div className="col text-center" style={{ color: '#d94440' }}>
                                        ${Vendedor.Pendiente} - {((Vendedor.Pendiente / montoFacturado) * 100).toFixed(2)}%
                                      </div>
                                      <div className="col text-center" style={{ color: '#96bc47' }}>
                                        ${Vendedor.CanceladoMasAbonado} - {((Vendedor.CanceladoMasAbonado / montoFacturado) * 100).toFixed(2)}%
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div className="division my-5"></div>
                          <div className="row">
                            <div className="col">
                              <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                                <h4 className="belmeny-text text-center mx-auto fst-italic">Estimaciones del mes: <span className="text-capitalize">{mesSelected}</span> <br /> Zona: {zonaSelected}</h4>

                                <div className='row'>
                                  <div className="col">
                                    <Chart
                                      options={chartEstimaciones.options}
                                      series={chartEstimaciones.series}
                                      type="bar"
                                      width="100%"
                                      height="500px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="division my-5"></div>

                          <div className="row">
                            <div className="col">
                              <div className="m-auto p-3 drop-shadow-card rounded" style={{background: '#3268a9'}}>
                                <h4 className="text-center text-white">Facturado</h4>
                                <h5 className="text-center text-white">{facturadoPorcentaje.toFixed(2)}%</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="m-auto p-3 drop-shadow-card rounded" style={{background: '#d94440'}}>
                                <h4 className="text-center text-white">FAC/NE Pendiente</h4>
                                <h5 className="text-center text-white">{pendientePorcentaje.toFixed(2)}%</h5>
                              </div>
                            </div>
                            <div className="col">
                              <div className="m-auto p-3 drop-shadow-card rounded" style={{background: '#96bc47'}}>
                                <h4 className="text-center text-white">FAC/NE Cancelado</h4>
                                <h5 className="text-center text-white">{canceladoPorcentaje.toFixed(2)}%</h5>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <div className="row">
                          <div className="col">
                            <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                              <h4 className="belmeny-text text-center mx-auto fst-italic">Debe escoger un mes y zona para generar el reporte de estimaciones...</h4>
                            </div>
                          </div>
                        </div>
                    }

                    <div className="division my-5"></div>



                  </div>
                </div>
              </>
          }
        </div>
      </Layout >
    </>
  )
}
