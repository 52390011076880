import React, { useState } from 'react'

import { formatNumber } from '../../utils/formats';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'
import coronaSvg from '../../assets/img/logos/CORONA-SVG.svg'
import wadfowSvg from '../../assets/img/logos/WADFOW-SVG.svg'

export const TopProductos = ({ topProductos }) => {

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const sumTotalVendido = topProductos.reduce((acc, productos) => {
    const totalVendidoVert = productos.TotalVendidoVert || 0;
    const totalVendidoIngco = productos.TotalVendidoIngco || 0;
    const totalVendidoImou = productos.TotalVendidoImou || 0;
    const totalVendidoWadfow = productos.TotalVendidoWadfow || 0;
    const totalVendidoCorona = productos.TotalVendidoCorona || 0;
    const totalVendidoFleximatic = productos.TotalVendidoFleximatic || 0;
    const totalVendidoQuilosa = productos.TotalVendidoQuilosa || 0;
    return acc + totalVendidoVert + totalVendidoIngco + totalVendidoImou + totalVendidoWadfow + totalVendidoCorona + totalVendidoFleximatic + totalVendidoQuilosa;
  }, 0);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
            <h4 className="belmeny-text mb-3">Ventas por marca</h4>
            <h5 className="belmeny-text mb-3">Total vendido en el mes: <span className="text-success fw-bold">${formatNumber(sumTotalVendido.toFixed(2))}</span></h5>
            <h6 className="text-muted fw-normal mb-3">Para poder visualizar los datos haga click en el gerente que desea ver y la tarjeta se expandirá</h6>
            <div className="container-fluid h-100">
              <div className="accordion mt-2" id="accordionVxC">
                {
                  topProductos.map((vxc, idx) =>
                    <>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading${vxc.CodSupervisor}`}>
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${vxc.CodSupervisor}`} aria-expanded="true" aria-controls={`#collapse${vxc.CodSupervisor}`}>
                            {
                              (vxc.CodSupervisor === "S01") ? <span>{vxc.CodSupervisor} - Lissette Nuñez</span> :
                                (vxc.CodSupervisor === "S02") ? <span>{vxc.CodSupervisor} - Luis Sastre</span> :
                                  (vxc.CodSupervisor === "S03") ? <span>{vxc.CodSupervisor} - Javier Villasmil</span> :
                                    (vxc.CodSupervisor === "S04") ? <span>{vxc.CodSupervisor} - Adel Codallo</span> : null
                            }
                          </button>
                        </h2>
                        <div id={`collapse${vxc.CodSupervisor}`} className="accordion-collapse collapse" aria-labelledby={`heading${vxc.CodSupervisor}`} data-bs-parent="#accordionVxC">
                          <div className="accordion-body">
                            <div className="row bg-belmeny3 text-white py-3 rounded">
                              <div className="col-3">
                                <h5 className="text-uppercase">Marca</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-center">Productos Vendidos</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-center">Ventas Generadas</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-center">% Representado</h5>
                              </div>
                            </div>
                            {/* VERT */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (vxc.CantidadProductosVert === null || vxc.CantidadProductosVert === 0) ? 0 : vxc.CantidadProductosVert
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoVert === null || vxc.TotalVendidoVert === 0) ? 0 : formatNumber(vxc.TotalVendidoVert.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoVert === null || vxc.TotalVendidoVert === 0) ? 0 : ((vxc.TotalVendidoVert * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* INGCO */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal">
                                  {
                                    (vxc.CantidadProductosIngco === null || vxc.CantidadProductosIngco === 0) ? 0 : vxc.CantidadProductosIngco
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoIngco === null || vxc.TotalVendidoIngco === 0) ? 0 : formatNumber(vxc.TotalVendidoIngco.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoIngco === null || vxc.TotalVendidoIngco === 0) ? 0 : ((vxc.TotalVendidoIngco * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* QUILOSA */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (vxc.CantidadProductosQuilosa === null || vxc.CantidadProductosQuilosa === 0) ? 0 : vxc.CantidadProductosQuilosa
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoQuilosa === null || vxc.TotalVendidoQuilosa === 0) ? 0 : formatNumber(vxc.TotalVendidoQuilosa.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoQuilosa === null || vxc.TotalVendidoQuilosa === 0) ? 0 : ((vxc.TotalVendidoQuilosa * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* IMOU */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (vxc.CantidadProductosImou === null || vxc.CantidadProductosImou === 0) ? 0 : vxc.CantidadProductosImou
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoImou === null || vxc.TotalVendidoImou === 0) ? 0 : formatNumber(vxc.TotalVendidoImou.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoImou === null || vxc.TotalVendidoImou === 0) ? 0 : ((vxc.TotalVendidoImou * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* FLEXIMATIC */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (vxc.CantidadProductosFleximatic === null || vxc.CantidadProductosFleximatic === 0) ? 0 : vxc.CantidadProductosFleximatic
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoFleximatic === null || vxc.TotalVendidoFleximatic === 0) ? 0 : formatNumber(vxc.TotalVendidoFleximatic.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoFleximatic === null || vxc.TotalVendidoFleximatic === 0) ? 0 : ((vxc.TotalVendidoFleximatic * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* CORONA */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={coronaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal">
                                  {
                                    (vxc.CantidadProductosCorona === null || vxc.CantidadProductosCorona === 0) ? 0 : vxc.CantidadProductosCorona
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoCorona === null || vxc.TotalVendidoCorona === 0) ? 0 : formatNumber(vxc.TotalVendidoCorona.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoCorona === null || vxc.TotalVendidoCorona === 0) ? 0 : ((vxc.TotalVendidoCorona * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* WADFOW */}
                            <div className="row mt-4">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-2"><img src={wadfowSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal">
                                  {
                                    (vxc.CantidadProductosWadfow === null || vxc.CantidadProductosWadfow === 0) ? 0 : vxc.CantidadProductosWadfow
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal text-success">
                                  {
                                    (vxc.TotalVendidoWadfow === null || vxc.TotalVendidoWadfow === 0) ? 0 : formatNumber(vxc.TotalVendidoWadfow.toFixed(2))
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-3 fw-normal text-info">
                                  {
                                    (vxc.TotalVendidoWadfow === null || vxc.TotalVendidoWadfow === 0) ? 0 : ((vxc.TotalVendidoWadfow * 100) / sumTotalVendido).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}