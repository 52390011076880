// Logos WEBP
export const belmenyWebp = require('./logos/BELMENY-WEBP.webp')
export const ingcoWebp = require('./logos/INGCO-WEBP.webp')
export const vertWebp = require('./logos/VERT-WEBP.webp')
export const quilosaWebp = require('./logos/QUILOSA-WEBP.webp')
export const fleximaticWebp = require('./logos/FLEXIMATIC-WEBP.webp')
export const imouWebp = require('./logos/IMOU-WEBP.webp')

// Logos SVG
export const belmenySvg = require('./logos/BELMENY-SVG.svg')
export const ingcoSvg = require('./logos/INGCO-SVG.svg')
export const vertSvg = require('./logos/VERT-SVG.svg')
export const quilosaSvg = require('./logos/QUILOSA-SVG.svg')
export const fleximaticSvg = require('./logos/FLEXIMATIC-SVG.svg')
export const imouSvg = require('./logos/IMOU-SVG.svg')

// Logos JPG
export const belmenyJpg = require('./logos/BELMENY-JPG.jpg')
export const ingcoJpg = require('./logos/INGCO-JPG.jpg')
export const vertJpg = require('./logos/VERT-JPG.jpg')
export const quilosaJpg = require('./logos/QUILOSA-JPG.jpg')
export const fleximaticJpg = require('./logos/FLEXIMATIC-JPG.jpg')
export const imouJpg = require('./logos/IMOU-JPG.jpg')

// Cintillos
export const cintilloVertProductos = require('./img-catalogo/cintillos/cintillo-vert-logo-productos-2.png')
export const cintilloVertProductosON = require('./img-catalogo/cintillos/cintillo-vert-logo-productos-ON.png')
export const cintilloCoronaProductos = require('./img-catalogo/cintillos/cintillo-corona-logo-productos.png')
export const cintilloCoronaProductosON = require('./img-catalogo/cintillos/cintillo-corona-logo-productos-ON.png')
export const cintilloCoronaProductosAdriano = require('./img-catalogo/cintillos/cintillo-corona-logo-adriano.png')
export const cintilloFleximaticProductos = require('./img-catalogo/cintillos/cintillo-fleximatic-logo-productos.png')
export const cintilloFleximaticProductosON = require('./img-catalogo/cintillos/cintillo-fleximatic-logo-productos-ON.png')
export const cintilloImouProductos = require('./img-catalogo/cintillos/cintillo-imou-logo-productos.png')
export const cintilloImouProductosON = require('./img-catalogo/cintillos/cintillo-imou-logo-productos-ON.png')
export const cintilloIngcoProductos = require('./img-catalogo/cintillos/cintillo-ingco-logo-productos.png')
export const cintilloIngcoProductosON = require('./img-catalogo/cintillos/cintillo-ingco-logo-productos-ON.png')
export const cintilloQuilosaProductos = require('./img-catalogo/cintillos/cintillo-quilosa-logo-productos.png')
export const cintilloQuilosaProductosON = require('./img-catalogo/cintillos/cintillo-quilosa-logo-productos-ON.png')

// Portadas
export const portadaVert = require('./img-catalogo/portadas/portada-vert.png')
export const portadaVertON = require('./img-catalogo/portadas/portada-vert-ON.png')
export const portadaIngco = require('./img-catalogo/portadas/portada-ingco.png')
export const portadaIngcoON = require('./img-catalogo/portadas/portada-ingco-ON.png')
export const portadaQuilosa = require('./img-catalogo/portadas/portada-quilosa.png')
export const portadaQuilosaON = require('./img-catalogo/portadas/portada-quilosa-ON.png')
export const portadaCorona = require('./img-catalogo/portadas/portada-corona.png')
export const portadaCoronaON = require('./img-catalogo/portadas/portada-corona-ON.png')
export const portadaImou = require('./img-catalogo/portadas/portada-imou.png')
export const portadaImouON = require('./img-catalogo/portadas/portada-imou-ON.png')
export const portadaFleximatic = require('./img-catalogo/portadas/portada-fleximatic.png')
export const portadaFleximaticON = require('./img-catalogo/portadas/portada-fleximatic-ON.png')
export const portadaWadfow = require('./img-catalogo/portadas/portada-wadfow.png')
export const portadaWadfowON = require('./img-catalogo/portadas/portada-wadfow-ON.png')


// Tips
export const tip_titulo = require('./tips_desktop/INICIO.webp');
export const tip_1 = require('./tips_desktop/TIP_1.webp');
export const tip_2 = require('./tips_desktop/TIP_2.webp');
export const tip_3 = require('./tips_desktop/TIP_3.webp');
export const tip_4 = require('./tips_desktop/TIP_4.webp');
export const tip_5 = require('./tips_desktop/TIP_5.webp');
export const tip_6 = require('./tips_desktop/TIP_6.webp');
export const tip_7 = require('./tips_desktop/TIP_7.webp');
export const tip_8 = require('./tips_desktop/TIP_8.webp');
export const tip_9 = require('./tips_desktop/TIP_9.webp');
export const tip_10 = require('./tips_desktop/TIP_10.webp');
export const tip_11 = require('./tips_desktop/TIP_11.webp');
export const tip_12 = require('./tips_desktop/TIP_12.webp');
export const tip_13 = require('./tips_desktop/TIP_13.webp');
export const tip_final = require('./tips_desktop/FINAL.webp');

// Tips para movil
export const tip_titulo_movil = require('./tips/tip_titulo.webp');
export const tip_1_movil = require('./tips/tip_1.webp');
export const tip_2_movil = require('./tips/tip_2.webp');
export const tip_3_movil = require('./tips/tip_3.webp');
export const tip_4_movil = require('./tips/tip_4.webp');
export const tip_5_movil = require('./tips/tip_5.webp');
export const tip_6_movil = require('./tips/tip_6.webp');
export const tip_7_movil = require('./tips/tip_7.webp');
export const tip_8_movil = require('./tips/tip_8.webp');
export const tip_9_movil = require('./tips/tip_9.webp');
export const tip_10_movil = require('./tips/tip_10.webp');
export const tip_11_movil = require('./tips/tip_11.webp');
export const tip_12_movil = require('./tips/tip_12.webp');
export const tip_13_movil = require('./tips/tip_13.webp');
export const tip_final_movil = require('./tips/tip_final.webp');

// Tips para laptops y tablets
export const tip_titulo_tablet = require('./tips_tablet/BIENVENIDA.webp');
export const tip_1_tablet = require('./tips_tablet/TIP_1.webp');
export const tip_2_tablet = require('./tips_tablet/TIP_2.webp');
export const tip_3_tablet = require('./tips_tablet/TIP_3.webp');
export const tip_4_tablet = require('./tips_tablet/TIP_4.webp');
export const tip_5_tablet = require('./tips_tablet/TIP_5.webp');
export const tip_6_tablet = require('./tips_tablet/TIP_6.webp');
export const tip_7_tablet = require('./tips_tablet/TIP_7.webp');
export const tip_8_tablet = require('./tips_tablet/TIP_8.webp');
export const tip_9_tablet = require('./tips_tablet/TIP_9.webp');
export const tip_10_tablet = require('./tips_tablet/TIP_10.webp');
export const tip_11_tablet = require('./tips_tablet/TIP_11.webp');
export const tip_12_tablet = require('./tips_tablet/TIP_12.webp');
export const tip_13_tablet = require('./tips_tablet/TIP_13.webp');
export const tip_final_tablet = require('./tips_tablet/FINAL.webp');

//Eventos
export const standExpoLara = require('./noticias/eventos/stand_expolara.webp');
export const standExpoPortuguesa = require('./noticias/eventos/stand_expoportuguesa.webp');
export const standExpoPortuguesaVert = require('./noticias/eventos/stand_vert.webp');
export const standExpoPortuguesaIngco = require('./noticias/eventos/stand_ingco.webp');

//Tracking

//1. Pedido Creado
export const pedidoCreadoPendiente = require('./icons_tracking/1.pedidoCreadoPendiente.webp');
export const pedidoCreadoProceso = require('./icons_tracking/1.pedidoCreadoProceso.webp');
export const pedidoCreadoFinalizado = require('./icons_tracking/1.pedidoCreadoFinalizado.webp');
//2. Despachado en almacen
export const almacenProcesadoPendiente = require('./icons_tracking/2.almacenProcesadoPendiente.webp');
export const almacenProcesadoProceso = require('./icons_tracking/2.almacenProcesadoProceso.webp');
export const almacenProcesadoFinalizado = require('./icons_tracking/2.almacenProcesadoFinalizado.webp');
//3. Verificado y embalado
export const verificadoEmbaladoPendiente = require('./icons_tracking/3.verificadoEmbaladoPendiente.webp');
export const verificadoEmbaladoProceso = require('./icons_tracking/3.verificadoEmbaladoProceso.webp');
export const verificadoEmbaladoFinalizado = require('./icons_tracking/3.verificadoEmbaladoFinalizado.webp');
//4. Pedido facturado
export const pedidoFacturadoPendiente = require('./icons_tracking/4.pedidoFacturadoPendiente.webp');
export const pedidoFacturadoProceso = require('./icons_tracking/4.pedidoFacturadoProceso.webp');
export const pedidoFacturadoFinalizado = require('./icons_tracking/4.pedidoFacturadoFinalizado.webp');
//5. Guia enviada al vendedor
export const guiaEnviadaPendiente = require('./icons_tracking/5.guiaEnviadaPendiente.webp');
export const guiaEnviadaProceso = require('./icons_tracking/5.guiaEnviadaProceso.webp');
export const guiaEnviadaFinalizado = require('./icons_tracking/5.guiaEnviadaFinalizado.webp');
//5. Salida a reparto
export const salidaRepartoPendiente = require('./icons_tracking/6.salidaRepartoPendiente.webp');
export const salidaRepartoProceso = require('./icons_tracking/6.salidaRepartoProceso.webp');
export const salidaRepartoFinalizado = require('./icons_tracking/6.salidaRepartoFinalizado.webp');
