import axios from "axios";
import { baseURL } from "./ApiURL"


// Metas Semanales
export async function getMetasSemanalVendedores(CodSupervisor) {
  try {
    const res = await axios.get(baseURL + `/getMetasSemanalVendedores?CodSupervisor=${CodSupervisor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
DASHBOARD SUPERVISOR Y/O GERENTE
==================================================================================
*/


export async function getVentasxZonaNacional(year) {
  try {
    const res = await axios.get(baseURL + `/getVentasZonasPorYear?year=${year}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getVentasxZonaMensual(zona) {
  try {
    const res = await axios.get(baseURL + `/getVentasZonasMensual?zona=${zona}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopVendedoresNacional(year) {
  try {
    const res = await axios.get(baseURL + `/getTopVendedoresPorYear?year=${year}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopVendedoresYearZona(year, zona) {
  try {
    const res = await axios.get(baseURL + `/getTopVendedoresYearZona?year=${year}&zona=${zona}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getVentasNacionalesPorYear(year) {
  try {
    const res = await axios.get(baseURL + `/getVentasNacionalesPorYear?year=${year}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getVentasZona(CodSupervisor, Zona) {
  let res;
  try {
    if (Zona !== undefined) {
      res = await axios.get(baseURL + `/getVentasZona?CodSupervisor=${CodSupervisor}&Zona=${Zona}`);
    } else {
      res = await axios.get(baseURL + `/getVentasZona?CodSupervisor=${CodSupervisor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getParetos(CodSupervisor, gen, orden) {
  try {
    const res = await axios.get(baseURL + `/getParetos?CodSupervisor=${CodSupervisor}&gen=${gen}&orderBy=${orden}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopVendedoresSupervisor(CodSupervisor, Sector) {
  try {
    const res = await axios.get(baseURL + `/getTopVendedoresSupervisor?CodSupervisor=${CodSupervisor}&Sector=${Sector}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTop10Vendedores() {
  try {
    const res = await axios.get(baseURL + `/getTop10Vendedores`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getVendedoresXSupervisor(CodSupervisor) {
  try {
    const res = await axios.get(baseURL + `/getVendedoresXSupervisor?CodSupervisor=${CodSupervisor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getZonasSupervisor(CodSupervisor) {
  try {
    const res = await axios.get(baseURL + `/getZonasSupervisor?CodSupervisor=${CodSupervisor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCorteSemanalxZona(CodSupervisor, otrasZonas) {
  try {
    let res;

    if (otrasZonas === 'si') {
      res = await axios.get(baseURL + `/getCorteSemanalxZona?CodSupervisor=${CodSupervisor}&otrasZonas=si`);
    } else {
      res = await axios.get(baseURL + `/getCorteSemanalxZona?CodSupervisor=${CodSupervisor}`);
    }

    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCantidadVentasxGerente(CodSupervisor, fechaInicio, fechaFin, Zona) {
  try {
    let res;
    if (fechaInicio !== null && fechaFin !== null) {
      if (Zona !== undefined) {
        res = await axios.get(baseURL + `/getCantidadVentasxGerente?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&Zona=${Zona}`);
      } else {
        res = await axios.get(baseURL + `/getCantidadVentasxGerente?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
      }
    } else {
      res = await axios.get(baseURL + `/getCantidadVentasxGerente?CodSupervisor=${CodSupervisor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getHistoricoMetas(CodSupervisor, fechaInicio, fechaFin, porVendedor, Zona) {
  try {
    let res;
    if (Zona !== undefined) {
      res = await axios.get(baseURL + `/getHistoricoMetas?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&porVendedor=${porVendedor}&Zona=${Zona}`);
    } else {
      res = await axios.get(baseURL + `/getHistoricoMetas?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&porVendedor=${porVendedor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function obtenerFPAPByZona(Zona) {
  try {
    const res = await axios.get(baseURL + `/obtenerFPAPByZona?Zona=${Zona}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getFPAPByZona(fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getFPAPByZona?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getEstimaciones(zona, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getEstimaciones?zona=${zona}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTotalFPC(fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getTotalFPC?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function statusPedidos() {
  try {
    const res = await axios.get(baseURL + `/statusPedidos`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getPedidosPorVendedor(fechaInicio, fechaFin, zona, CodSupervisor) {
  try {
    const res = await axios.get(baseURL + `/getPedidosPorVendedor?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&zona=${zona}&CodSupervisor=${CodSupervisor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getCobrosAnualesEnCurso(year) {
  try {
    const res = await axios.get(baseURL + `/getCobrosAnualesEnCurso?year=${year}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getFacturasVencidas(Supervisor) {
  let res
  try {
    if (Supervisor !== undefined) {
      res = await axios.get(baseURL + `/getFacturasVencidas?CodSupervisor=${Supervisor}`);
    } else {
      res = await axios.get(baseURL + `/getFacturasVencidas`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getFacturasEmitidas(Supervisor) {
  let res
  try {
    if (Supervisor !== undefined) {
      res = await axios.get(baseURL + `/getFacturasEmitidas?CodSupervisor=${Supervisor}`);
    } else {
      res = await axios.get(baseURL + `/getFacturasEmitidas`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClientesCaptados(Supervisor) {
  let res
  try {
    if (Supervisor !== undefined) {
      res = await axios.get(baseURL + `/getClientesCaptados?CodSupervisor=${Supervisor}`);
    } else {
      res = await axios.get(baseURL + `/getClientesCaptados`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopClientes(Supervisor, gen) {
  let res;
  try {
    if (Supervisor) {
      res = await axios.get(baseURL + `/getTopClientes?CodSupervisor=${Supervisor}&gen=${gen}`);
    } else {
      res = await axios.get(baseURL + `/getTopClientes?gen=${gen}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getFacturadoAndCobradoHoyVsAyer() {
  try {
    const res = await axios.get(baseURL + `/getFacturadoAndCobradoHoyVsAyer`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getVentasAnualesEnCursoSupervisores(Supervisor, year) {
  try {
    const res = await axios.get(baseURL + `/getVentasAnualesEnCursoSupervisores?CodigoSupervisor=${Supervisor}&year=${year}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVxCVendedor(Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVxCVendedor?CodVendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getCobranzasSupervisor(Supervisor, year) {
  try {
    const res = await axios.get(baseURL + `/getCobranzasSupervisor?CodSupervisor=${Supervisor}&year=${year}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getDetalleCobranzasSupervisor(Supervisor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getDetalleCobranzasSupervisor?CodSupervisor=${Supervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVendedoresConZonaPorSupervisor(Supervisor) {
  try {
    const res = await axios.get(baseURL + `/getVendedoresConZonaPorSupervisor?CodSupervisor=${Supervisor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
METAS
==================================================================================
*/
export async function getListaMetas(zona, CodSupervisor) {
  try {
    let res;
    if (zona) {
      res = await axios.get(baseURL + `/getListaMetas?zona=${zona}&CodSupervisor=${CodSupervisor}`);
    }
    else {
      res = await axios.get(baseURL + `/getListaMetas?CodSupervisor=${CodSupervisor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function actualizarMetas(vendedor, metaGlobal, firstday, lastday) {
  try {
    const res = await axios.put(baseURL + `/ActualizarMetas?vendedor=${vendedor}&metaGlobal=${metaGlobal}&PeriodoInicio=${firstday}&PeriodoFin=${lastday}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

