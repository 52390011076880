import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../../components/Layout';
import AuthUser from '../../../components/AuthUser';

import Chart from "react-apexcharts";
import { Modal } from 'react-bootstrap';
import { Dropdown, ButtonToolbar } from 'rsuite';
import { ChevronRight } from 'lucide-react';
import { RiCloseCircleFill } from "react-icons/ri";

import otherUserIcon from '../../../assets/img/user-icon-resize.webp';

import { getVendedoresConZonaPorSupervisor as getVendedores, getVxCVendedor } from '../../../api/requestSupervisor';
import { getMetasPorVendedor, getVentasAnualesEnCurso, getVentasVendedor, getClientesAtendidos } from '../../../api/request';

import { formatNumber } from '../../../utils/formats';

// SVG LOGOS
import brokenImg from '../../../assets/img/broken-image-general.png';
import belmenySvg from '../../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../../assets/img/logos/IMOU-SVG.svg'
import coronaSvg from '../../../assets/img/logos/CORONA-SVG.svg'
import wadfowSvg from '../../../assets/img/logos/WADFOW-SVG.svg'

export const GestionVendedor = () => {

	// State del usuario
	const { user } = AuthUser();

	// States del modal
	const [fullscreen, setFullscreen] = useState(false);
	const [show, setShow] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalBody, setModalBody] = useState("");
	const [modalLoading, setModalLoading] = useState("");

	// State del loading
	const [loading, setLoading] = useState(true);

	// State de datos
	const [userIcon, setUserIcon] = useState(``);
	const [vendedorSelected, setVendedorSelected] = useState('Selecciona un vendedor')
	const [vendido2022, setVendido2022] = useState(0)
	const [vendido2023, setVendido2023] = useState(0)
	const [vendido2024, setVendido2024] = useState(0)
	const [totalVendidoMes, setTotalVendidoMes] = useState(0)
	const [vendedoresData, setVendedoresData] = useState([])
	const [sectores, setSectores] = useState([])
	const [vXc, setVxC] = useState({})
	const [chartVentasAnuales, setChartVentasAnuales] = useState({})

	const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

	function sortByMonth(arr) {
		arr.sort(function (a, b) {
			return labels.indexOf(a.months)
				- labels.indexOf(b.months);
		});
	}

	const handleImageError = (event) => {
		event.target.src = brokenImg;
	};

	const CheckImg = async () => {
		await axios.get(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`).then(() => {
			setUserIcon(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`)
			return true;
		})
			.catch(() => {
				setUserIcon(otherUserIcon)
				return false;
			});
	}

	const loadInitialData = async () => {
		const resVendedores = await getVendedores(user.CodVendedor)
		const vendedores = await resVendedores
		setSectores([...new Set(vendedores.map((item) => item.Sector))])
		setVendedoresData(vendedores)
	}

	const selectVendedor = async (codigo) => {
		setLoading(true)
		const vendedor = vendedoresData.filter(item => item.CodVendedor === codigo)
		setVendedorSelected(`${codigo} - ${vendedor[0].Nombre}`)

		const [resMetas, resVentas2022, resVentas2023, resVentas2024, resVxC] = await Promise.all([getMetasPorVendedor(codigo), getVentasVendedor('2022', codigo), getVentasVendedor('2023', codigo), getVentasAnualesEnCurso(codigo), getVxCVendedor(codigo)])

		const metasRes = await resMetas
		const ventas2022 = await resVentas2022
		const ventas2023 = await resVentas2023
		const ventas2024 = await resVentas2024
		const vxc = await resVxC

		console.log(metasRes.data[0])
		console.log(vxc)

		sortByMonth(ventas2022)
		sortByMonth(ventas2023)

		const ventasAñoActual = labels.map(label => (ventas2024[label] === null) ? 0 : ventas2024[label]);

		setVendido2022(ventas2022.reduce((acc, current) => acc + parseFloat(current.ventas), 0).toFixed(2))
		setVendido2023(ventas2023.reduce((acc, current) => acc + parseFloat(current.ventas), 0).toFixed(2))
		let sumVentasAñoActual = 0
		for (let i = 0; i < ventasAñoActual.length; i++) {
			sumVentasAñoActual += ventasAñoActual[i];
		}
		setVendido2024(sumVentasAñoActual.toFixed(2))
		setTotalVendidoMes(metasRes.data[0].total_vendido)
		setVxC(vxc)
		setChartVentasAnuales({
			options: {
				chart: {
					id: "chart_ventas_anuales",
					type: "area"
				},
				xaxis: {
					categories: labels
				},
				fill: {
					type: "gradient",
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.7,
						opacityTo: 0.9,
						stops: [0, 90, 100]
					}
				},
				colors: ['#d62915', '#0041ff', '#52d689'],
				dataLabels: {
					enabled: false
				},
			},
			series: [
				{
					name: 'Ventas del año 2022 en USD',
					data: ventas2022.map((Ventas) => Ventas.ventas.toFixed(2))
				},
				{
					name: 'Ventas del año 2023 en USD',
					data: ventas2023.map((Ventas) => Ventas.ventas.toFixed(2))
				},
				{
					name: 'Ventas del año actual en USD',
					data: ventasAñoActual.map((Ventas) => Ventas.toFixed(2))
				}
			]
		})
		setShow(false)
		setLoading(false)
	}

	useEffect(() => {
		CheckImg()
		loadInitialData()
		setLoading(false)
	}, [])

	return (
		<Layout>
			<div className="container-fluid">
				{
					(loading) ?
						<>
							<div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
								<div className="loader-book">
									<div>
										<ul>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
											<li>
												<svg fill="currentColor" viewBox="0 0 90 120">
													<path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
												</svg>
											</li>
										</ul>
									</div>
									<span>Cargando estadisticas...</span>
								</div>
							</div>
						</> :
						<div className='d-none d-md-block'>
							<div className="container-fluid rounded">
								<div className="row my-3">
									<div className="col">
										<span>
											<h5><span className="badge rounded bg-primary">Inicio</span> <ChevronRight size={20} color="#000000" absoluteStrokeWidth /> <a href="/dashboard-gerente">Panel de control</a></h5>
										</span>
									</div>
									<div className="col text-end belmeny-text">
										<div className="row">
											<div className="col offset-6">
												<img src={userIcon} alt="No se ha cargado la imagen..." className='float-end drop-shadow rounded-circle' style={{ width: "60px", height: "60px", border: "3px solid rgba(0, 41, 117, 1)" }} />
											</div>
											<div className="col d-flex align-items-center justify-content-center">
												<h4>{user.Nombre}</h4>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-3 bg-white drop-shadow-card p-3 rounded">
										<div className="ms-2 belmeny-text">
											<h4 className='text-center'>Gestión de Vendedores</h4>
											<h5 className='my-2 text-center'><button className='buttonMonths'><span className="text-white" onClick={(e) => setShow(true)}>{vendedorSelected}</span></button></h5>
										</div>
									</div>
									<div className="col-1" style={{ width: '1.5%' }}>
										<div className="division h-100 bg-belmeny" style={{ width: '5px' }}></div>
									</div>
									<div className="col bg-white drop-shadow-card p-3 rounded">
										<div className="ms-2 belmeny-text text-center">
											<h4>Año 2024</h4>
											<h4>Facturado en el periodo:</h4>
											<h5 className="text-success">{formatNumber(vendido2024)} USD</h5>
										</div>
									</div>
									<div className="col-1" style={{ width: '1.5%' }}>
										<div className="division h-100 bg-belmeny" style={{ width: '5px' }}></div>
									</div>
									<div className="col bg-white drop-shadow-card p-3 rounded">
										<div className="ms-2 belmeny-text text-center">
											<h4>Año 2023</h4>
											<h4>Facturado en el periodo:</h4>
											<h5 className="text-success">{formatNumber(vendido2023)} USD</h5>
										</div>
									</div>
									<div className="col-1" style={{ width: '1.5%' }}>
										<div className="division h-100 bg-belmeny" style={{ width: '5px' }}></div>
									</div>
									<div className="col bg-white drop-shadow-card p-3 rounded">
										<div className="ms-2 belmeny-text text-center">
											<h4>Año 2022</h4>
											<h4>Facturado en el periodo:</h4>
											<h5 className="text-success">{formatNumber(vendido2022)} USD</h5>
										</div>
									</div>
								</div>

								{
									(vendedorSelected !== 'Selecciona un vendedor') ?
										<>
											<div className="row mt-5 mb-3 bg-white drop-shadow-card p-3 rounded">
												<h4 className="text-center belmeny-text">Ventas anuales</h4>
												<Chart
													options={chartVentasAnuales.options}
													series={chartVentasAnuales.series}
													type="area"
													width="100%"
													height="450px"
													style={{ zIndex: 1 }}
												/>
											</div>

											<div className="row mt-3 mb-5 bg-white drop-shadow-card p-3 rounded">
												<h4 className="text-center belmeny-text">Ventas por marca del mes en curso</h4>
												<h5 className="belmeny-text mb-3">Total vendido en el mes: <span className="text-success fw-bold">${formatNumber(totalVendidoMes)}</span></h5>
												<div className="container-fluid h-100">
													<div className="row bg-belmeny3 text-white py-3 rounded-top">
														<div className="col-3">
															<h5 className="text-uppercase">Marca</h5>
														</div>
														<div className="col">
															<h5 className="text-uppercase text-center">Productos Vendidos</h5>
														</div>
														<div className="col">
															<h5 className="text-uppercase text-center">Ventas Generadas</h5>
														</div>
														<div className="col">
															<h5 className="text-uppercase text-center">% Representado</h5>
														</div>
													</div>
													{/* VERT */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal">
																{vXc.vert.TotalProductosVert}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-success">
																{formatNumber(vXc.vert.TotalVendidoVert)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-info">
																{formatNumber(((vXc.vert.TotalVendidoVert * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* INGCO */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-2 fw-normal">
																{vXc.ingco.TotalProductosIngco}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-2 fw-normal text-success">
																{formatNumber(vXc.ingco.TotalVendidoIngco)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-2 fw-normal text-info">
																{formatNumber(((vXc.ingco.TotalVendidoIngco * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* QUILOSA */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal">
																{vXc.quilosa.TotalProductosQuilosa}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-success">
																{formatNumber(vXc.quilosa.TotalVendidoQuilosa)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-info">
																{formatNumber(((vXc.quilosa.TotalVendidoQuilosa * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* IMOU */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal">
																{vXc.imou.TotalProductosImou}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-success">
																{formatNumber(vXc.imou.TotalVendidoImou)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-info">
																{formatNumber(((vXc.imou.TotalVendidoImou * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* FLEXIMATIC */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal">
																{vXc.fleximatic.TotalProductosFleximatic}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-success">
																{formatNumber(vXc.fleximatic.TotalVendidoFleximatic)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-4 fw-normal text-info">
																{formatNumber(((vXc.fleximatic.TotalVendidoFleximatic * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* CORONA */}
													<div className="row mt-3">
														<div className="col-3">
															<h5 className="text-uppercase pt-1"><img src={coronaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal">
																{vXc.corona.TotalProductosCorona}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal text-success">
																{formatNumber(vXc.corona.TotalVendidoCorona)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal text-info">
																{formatNumber(((vXc.corona.TotalVendidoCorona * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
													{/* WADFOW */}
													<div className="row mt-4">
														<div className="col-3">
															<h5 className="text-uppercase pt-2"><img src={wadfowSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal">
																{vXc.wadfow.TotalProductosWadfow}
																<span className="ps-1">Productos</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal text-success">
																{formatNumber(vXc.wadfow.TotalVendidoWadfow)}
																<span className="ps-1">USD</span></h5>
														</div>
														<div className="col">
															<h5 className="text-center text-uppercase pt-3 fw-normal text-info">
																{formatNumber(((vXc.wadfow.TotalVendidoWadfow * 100) / totalVendidoMes).toFixed(2))}%
															</h5>
														</div>
													</div>
												</div>
											</div>
										</>
										:
										<>
											<div className="row mt-3 mb-5 bg-white drop-shadow-card p-3 rounded">
												<h3 className='text-center belmeny-text'>Selecciona un vendedor haciendo click en el botón para poder ver sus estadisticas</h3>
											</div>
										</>
								}
							</div>
						</div>
				}
			</div>

			<Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
				<Modal.Header className='bg-belmeny text-light'>
					<Modal.Title className='fst-italic'>Selección de vendedor</Modal.Title>
					<Modal.Title>
						<RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
					</Modal.Title>
				</Modal.Header>
				<div className="division w-100 my-2 bg-belmeny"></div>
				<div className="container w-100">
					<h5 className="text-center belmeny-text mb-2">Selecciona un vendedor en el menú a continuación:</h5>
					<ButtonToolbar className='mx-auto w-100'>
						<Dropdown title="Vendedores" appearance="primary" size="lg" placement="rightStart" onSelect={selectVendedor} >
							{
								sectores.map((sector) => (
									<Dropdown.Menu title={sector} key={sector} style={{ zIndex: 999 }}>
										{
											vendedoresData.filter(item => item.Sector === sector).map((item) => (
												<Dropdown.Item eventKey={item.CodVendedor}>{item.CodVendedor} - {item.Nombre}</Dropdown.Item>
											))
										}
									</Dropdown.Menu>
								))
							}
						</Dropdown>
					</ButtonToolbar>
				</div>
				<Modal.Body>
				</Modal.Body>
			</Modal>
		</Layout>
	)
}