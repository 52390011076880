import React, { useState, useEffect } from 'react'

import AuthUser from '../AuthUser';
import { getDetalleCobranzasSupervisor } from '../../api/requestSupervisor';
import { CobranzasGerenciaXLS } from '../../views/XLS/CobranzasGerenciaXLS';

export const CobranzasGerencia = ({ mes, año }) => {

    const { user, getToken } = AuthUser();
    const [loading, setLoading] = useState(true);
    const [fechaInicio, setFechaInicio] = useState("")
    const [fechaFin, setFechaFin] = useState("")
    const [result, setResult] = useState({})

    useEffect(() => {
        switch (mes) {
            case "Enero":
                setFechaInicio("" + año + "-01-01 00:00:00")
                setFechaFin("" + año + "-01-31 23:59:59")
                break;
            case "Febrero":
                setFechaInicio("" + año + "-02-01 00:00:00")
                setFechaFin("" + año + "-02-29 23:59:59")
                break;
            case "Marzo":
                setFechaInicio("" + año + "-03-01 00:00:00")
                setFechaFin("" + año + "-03-31 23:59:59")
                break;
            case "Abril":
                setFechaInicio("" + año + "-04-01 00:00:00")
                setFechaFin("" + año + "-04-30 23:59:59")
                break;
            case "Mayo":
                setFechaInicio("" + año + "-05-01 00:00:00")
                setFechaFin("" + año + "-05-31 23:59:59")
                break;
            case "Junio":
                setFechaInicio("" + año + "-06-01 00:00:00")
                setFechaFin("" + año + "-06-30 23:59:59")
                break;
            case "Julio":
                setFechaInicio("" + año + "-07-01 00:00:00")
                setFechaFin("" + año + "-07-31 23:59:59")
                break;
            case "Agosto":
                setFechaInicio("" + año + "-08-01 00:00:00")
                setFechaFin("" + año + "-08-31 23:59:59")
                break;
            case "Septiembre":
                setFechaInicio("" + año + "-09-01 00:00:00")
                setFechaFin("" + año + "-09-30 23:59:59")
                break;
            case "Octubre":
                setFechaInicio("" + año + "-10-01 00:00:00")
                setFechaFin("" + año + "-10-31 23:59:59")
                break;
            case "Noviembre":
                setFechaInicio("" + año + "-11-01 00:00:00")
                setFechaFin("" + año + "-11-30 23:59:59")
                break;
            case "Diciembre":
                setFechaInicio("" + año + "-12-01 00:00:00")
                setFechaFin("" + año + "-12-31 23:59:59")
                break;

            default:
                setFechaInicio("" + año + "-01-01 00:00:00")
                setFechaFin("" + año + "-01-31 23:59:59")
                break;
        }
    }, [mes, año])

    useEffect(() => {
        if (fechaInicio && fechaFin) {
            loadDetalle(fechaInicio, fechaFin);
        }
    }, [fechaInicio, fechaFin]);

    const loadDetalle = async (startDate, endDate) => {
        setLoading(true)
        const resDetalleCobranzasSup = await getDetalleCobranzasSupervisor(user.CodVendedor, startDate, endDate)
        setResult(resDetalleCobranzasSup)
        setLoading(false)
    }


    return (
        <>
            <h4 className='belmeny-text text-center my-2'>Documentos pendientes de {mes} del año {año} <span className='float-end'><CobranzasGerenciaXLS data={result} mes={mes} año={año}/></span></h4>
            <div className="row bg-belmeny3 text-white py-3 rounded">
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Zona</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Documento</h5>
                </div>
                <div className="col-3 d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Cliente</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Vendedor</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Emisión</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Dia(s) vencido(s)</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Dias de crédito</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Monto facturado</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Monto pendiente</h5>
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h5 className="text-uppercase text-center">Estado</h5>
                </div>
            </div>
            {
                (loading) ?
                    <>
                        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                            <div className="loader-book">
                                <div>
                                    <ul>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                        <li>
                                            <svg fill="currentColor" viewBox="0 0 90 120">
                                                <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                                <span>Cargando cobranzas...</span>
                            </div>
                        </div>
                    </> : <>
                        {
                            result.map((item, idx) => (
                                <>
                                    <div className="row text-center my-2" key={idx}>
                                        <div className="col">
                                            <h5>{item.Sector}</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className='text-danger'>{item.documento}</h5>
                                        </div>
                                        <div className="col-3">
                                            <h5>{item.nombrecli}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>{item.CodVendedor} - {item.Nombre}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>{new Date(item.FechaDocumento).toLocaleDateString('es-MX')}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>{item.DiasVencidos} día(s)</h5>
                                        </div>
                                        <div className="col">
                                            <h5>{item.DiasCredito} día(s)</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className='text-success'>{item.TotalFact.toFixed(2)} USD</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className='text-danger'>{item.TotalPend.toFixed(2)} USD</h5>
                                        </div>
                                        <div className="col">
                                            <h5>{
                                                (item.Estatus === 0 || item.TotalFact === item.TotalPend) ? <span className='text-danger'>Pendiente</span> : <span className='text-info'>Con abono</span>
                                            }</h5>
                                        </div>
                                    </div>
                                    <div className="division w-100 my-2 bg-belmeny3"></div>
                                </>
                            ))
                        }
                    </>
            }
        </>
    )
}
