import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import AuthUser from '../../../components/AuthUser';

import { Dropdown, DropdownButton } from 'react-bootstrap'

import userIcon from '../../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../../assets/img/404-module-not-found-2.webp';

import { getInfoCobranzaPorZona } from '../../../api/requestCobranzas';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export const InfoCobranza = () => {

  useEffect(() => {
    if(user.CodSupervisor != null)
    {
      setUsername(user.CodSupervisor)
    }
    else if(user.CodGerente != null)
    {
      setUsername(user.CodGerente)
    }
    setLoading(false)
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('')
  // Zulia
  const [chartZulia2Month, setChartZulia2Month] = useState({});
  const [chartZulia1Month, setChartZulia1Month] = useState({});
  const [chartZulia, setChartZulia] = useState({});

  // Llanos
  const [chartLlanos2Month, setChartLlanos2Month] = useState({});
  const [chartLlanos1Month, setChartLlanos1Month] = useState({});
  const [chartLlanos, setChartLlanos] = useState({});

  // Andina
  const [chartAndina2Month, setChartAndina2Month] = useState({});
  const [chartAndina1Month, setChartAndina1Month] = useState({});
  const [chartAndina, setChartAndina] = useState({});

  // Centro
  const [chartCentro2Month, setChartCentro2Month] = useState({});
  const [chartCentro1Month, setChartCentro1Month] = useState({});
  const [chartCentro, setChartCentro] = useState({});

  // Falcon
  const [chartFalcon2Month, setChartFalcon2Month] = useState({});
  const [chartFalcon1Month, setChartFalcon1Month] = useState({});
  const [chartFalcon, setChartFalcon] = useState({});

  // Tachira
  const [chartTachira2Month, setChartTachira2Month] = useState({});
  const [chartTachira1Month, setChartTachira1Month] = useState({});
  const [chartTachira, setChartTachira] = useState({});

  // Oriente
  const [chartOriente2Month, setChartOriente2Month] = useState({});
  const [chartOriente1Month, setChartOriente1Month] = useState({});
  const [chartOriente, setChartOriente] = useState({});

  // Caracas
  const [chartCaracas2Month, setChartCaracas2Month] = useState({});
  const [chartCaracas1Month, setChartCaracas1Month] = useState({});
  const [chartCaracas, setChartCaracas] = useState({});

  // Nombres de meses
  const [twoMonthsName, setTwoMonthsName] = useState('');
  const [oneMonthsName, setOneMonthsName] = useState('');
  const [monthsName, setMonthsName] = useState('');

  const [options, setOptions] = useState({});

  const [onlyCcs, setOnlyCcs] = useState(false);

  const [showInfo, setShowInfo] = useState('');

  const now = new Date();

  const handleFilter = async (e, zona) => {
    e.preventDefault();
    // 2 Meses atrás
    const TwoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1)
    setTwoMonthsName(TwoMonthsAgo.toLocaleString('default', { month: 'long' }))
    const firstDayTwoMonthAgo = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 2, 1)).slice(1, 11);
    const lastDayTwoMonthAgo = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 1, 0)).slice(1, 11);

    // 1 Mes atrás
    const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    setOneMonthsName(oneMonthAgo.toLocaleString('default', { month: 'long' }))
    const firstDayMonthAgo = JSON.stringify(new Date(now.getFullYear(), now.getMonth() - 1, 1)).slice(1, 11);
    const lastDayMonthAgo = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 0)).slice(1, 11);

    // Mes actual
    const actualMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    setMonthsName(actualMonth.toLocaleString('default', { month: 'long' }))
    const firstDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 1)).slice(1, 11);
    const lastDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth() + 1, 0)).slice(1, 11);

    switch (zona) {
      case 'Andina':

        setLoading(true)

        const cobranzaAndina2MesesAtras = await getInfoCobranzaPorZona('Andina', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaAndina1MesAtras = await getInfoCobranzaPorZona('Andina', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaAndina = await getInfoCobranzaPorZona('Andina', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaAndina.Cancelado.length) {
          cobranzaAndina.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaAndina.Abonado.length) {
          cobranzaAndina.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaAndina.Pendiente.length) {
          cobranzaAndina.Pendiente.push({ Pendiente: 0 })
        }

        setChartAndina2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaAndina2MesesAtras.Cancelado[0].Cancelado, cobranzaAndina2MesesAtras.Abonado[0].Abonado, cobranzaAndina2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartAndina1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaAndina1MesAtras.Cancelado[0].Cancelado, cobranzaAndina1MesAtras.Abonado[0].Abonado, cobranzaAndina1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartAndina({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaAndina.Cancelado[0].Cancelado, cobranzaAndina.Abonado[0].Abonado, cobranzaAndina.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showAndina')
        break;
      case 'Centro':
        setLoading(true)

        const cobranzaCentro2MesesAtras = await getInfoCobranzaPorZona('Centro', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaCentro1MesAtras = await getInfoCobranzaPorZona('Centro', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaCentro = await getInfoCobranzaPorZona('Centro', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaCentro.Cancelado.length) {
          cobranzaCentro.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaCentro.Abonado.length) {
          cobranzaCentro.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaCentro.Pendiente.length) {
          cobranzaCentro.Pendiente.push({ Pendiente: 0 })
        }

        setChartCentro2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCentro2MesesAtras.Cancelado[0].Cancelado, cobranzaCentro2MesesAtras.Abonado[0].Abonado, cobranzaCentro2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartCentro1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCentro1MesAtras.Cancelado[0].Cancelado, cobranzaCentro1MesAtras.Abonado[0].Abonado, cobranzaCentro1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartCentro({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCentro.Cancelado[0].Cancelado, cobranzaCentro.Abonado[0].Abonado, cobranzaCentro.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showCentro')
        break;
      case 'Falcon':
        setLoading(true)

        const cobranzaFalcon2MesesAtras = await getInfoCobranzaPorZona('Falcon', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaFalcon1MesAtras = await getInfoCobranzaPorZona('Falcon', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaFalcon = await getInfoCobranzaPorZona('Falcon', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaFalcon.Cancelado.length) {
          cobranzaFalcon.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaFalcon.Abonado.length) {
          cobranzaFalcon.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaFalcon.Pendiente.length) {
          cobranzaFalcon.Pendiente.push({ Pendiente: 0 })
        }

        setChartFalcon2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaFalcon2MesesAtras.Cancelado[0].Cancelado, cobranzaFalcon2MesesAtras.Abonado[0].Abonado, cobranzaFalcon2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartFalcon1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaFalcon1MesAtras.Cancelado[0].Cancelado, cobranzaFalcon1MesAtras.Abonado[0].Abonado, cobranzaFalcon1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartFalcon({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaFalcon.Cancelado[0].Cancelado, cobranzaFalcon.Abonado[0].Abonado, cobranzaFalcon.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showFalcon')
        break;
      case 'Gran Caracas':
        setLoading(true)

        const cobranzaCaracas2MesesAtras = await getInfoCobranzaPorZona('Gran Caracas', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaCaracas1MesAtras = await getInfoCobranzaPorZona('Gran Caracas', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaCaracas = await getInfoCobranzaPorZona('Gran Caracas', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaCaracas.Cancelado.length) {
          cobranzaCaracas.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaCaracas.Abonado.length) {
          cobranzaCaracas.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaCaracas.Pendiente.length) {
          cobranzaCaracas.Pendiente.push({ Pendiente: 0 })
        }

        setChartCaracas2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCaracas2MesesAtras.Cancelado[0].Cancelado, cobranzaCaracas2MesesAtras.Abonado[0].Abonado, cobranzaCaracas2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartCaracas1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCaracas1MesAtras.Cancelado[0].Cancelado, cobranzaCaracas1MesAtras.Abonado[0].Abonado, cobranzaCaracas1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartCaracas({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaCaracas.Cancelado[0].Cancelado, cobranzaCaracas.Abonado[0].Abonado, cobranzaCaracas.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showCaracas')
        break;
      case 'Llanos':
        setLoading(true)

        const cobranzaLlanos2MesesAtras = await getInfoCobranzaPorZona('Llanos', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaLlanos1MesAtras = await getInfoCobranzaPorZona('Llanos', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaLlanos = await getInfoCobranzaPorZona('Llanos', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaLlanos.Cancelado.length) {
          cobranzaLlanos.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaLlanos.Abonado.length) {
          cobranzaLlanos.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaLlanos.Pendiente.length) {
          cobranzaLlanos.Pendiente.push({ Pendiente: 0 })
        }

        setChartLlanos2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaLlanos2MesesAtras.Cancelado[0].Cancelado, cobranzaLlanos2MesesAtras.Abonado[0].Abonado, cobranzaLlanos2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartLlanos1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaLlanos1MesAtras.Cancelado[0].Cancelado, cobranzaLlanos1MesAtras.Abonado[0].Abonado, cobranzaLlanos1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartLlanos({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaLlanos.Cancelado[0].Cancelado, cobranzaLlanos.Abonado[0].Abonado, cobranzaLlanos.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showLlanos')
        break;
      case 'Oriente':
        setLoading(true)

        const cobranzaOriente2MesesAtras = await getInfoCobranzaPorZona('Oriente', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaOriente1MesAtras = await getInfoCobranzaPorZona('Oriente', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaOriente = await getInfoCobranzaPorZona('Oriente', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaOriente.Cancelado.length) {
          cobranzaOriente.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaOriente.Abonado.length) {
          cobranzaOriente.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaOriente.Pendiente.length) {
          cobranzaOriente.Pendiente.push({ Pendiente: 0 })
        }

        setChartOriente2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaOriente2MesesAtras.Cancelado[0].Cancelado, cobranzaOriente2MesesAtras.Abonado[0].Abonado, cobranzaOriente2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartOriente1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaOriente1MesAtras.Cancelado[0].Cancelado, cobranzaOriente1MesAtras.Abonado[0].Abonado, cobranzaOriente1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartOriente({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaOriente.Cancelado[0].Cancelado, cobranzaOriente.Abonado[0].Abonado, cobranzaOriente.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showOriente')
        break;
      case 'Tachira':
        setLoading(true)

        const cobranzaTachira2MesesAtras = await getInfoCobranzaPorZona('Tachira', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaTachira1MesAtras = await getInfoCobranzaPorZona('Tachira', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaTachira = await getInfoCobranzaPorZona('Tachira', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaTachira.Cancelado.length) {
          cobranzaTachira.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaTachira.Abonado.length) {
          cobranzaTachira.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaTachira.Pendiente.length) {
          cobranzaTachira.Pendiente.push({ Pendiente: 0 })
        }

        setChartTachira2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaTachira2MesesAtras.Cancelado[0].Cancelado, cobranzaTachira2MesesAtras.Abonado[0].Abonado, cobranzaTachira2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartTachira1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaTachira1MesAtras.Cancelado[0].Cancelado, cobranzaTachira1MesAtras.Abonado[0].Abonado, cobranzaTachira1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartTachira({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaTachira.Cancelado[0].Cancelado, cobranzaTachira.Abonado[0].Abonado, cobranzaTachira.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showTachira')
        break;
      case 'Zulia':
        setLoading(true)

        const cobranzaZulia2MesesAtras = await getInfoCobranzaPorZona('Zulia', firstDayTwoMonthAgo, lastDayTwoMonthAgo)
        const cobranzaZulia1MesAtras = await getInfoCobranzaPorZona('Zulia', firstDayMonthAgo, lastDayMonthAgo)
        const cobranzaZulia = await getInfoCobranzaPorZona('Zulia', firstDay, lastDay)

        // Validaciones del mes actual por si algun dato no aparece
        // Cancelado
        if (!cobranzaZulia.Cancelado.length) {
          cobranzaZulia.Cancelado.push({ Cancelado: 0 })
        }

        // Abonado
        if (!cobranzaZulia.Abonado.length) {
          cobranzaZulia.Abonado.push({ Abonado: 0 })
        }

        // Pendiente
        if (!cobranzaZulia.Pendiente.length) {
          cobranzaZulia.Pendiente.push({ Pendiente: 0 })
        }

        setChartZulia2Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaZulia2MesesAtras.Cancelado[0].Cancelado, cobranzaZulia2MesesAtras.Abonado[0].Abonado, cobranzaZulia2MesesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartZulia1Month({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaZulia1MesAtras.Cancelado[0].Cancelado, cobranzaZulia1MesAtras.Abonado[0].Abonado, cobranzaZulia1MesAtras.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setChartZulia({
          labels: ['Pagado', 'Abonado', 'Pendiente'],
          datasets: [
            {
              label: 'Información sobre pagos, abonos y pendientes',
              data: [cobranzaZulia.Cancelado[0].Cancelado, cobranzaZulia.Abonado[0].Abonado, cobranzaZulia.Pendiente[0].Pendiente],
              borderColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ],
              backgroundColor: [
                '#2ecc71',
                '#f1c40f',
                '#e74c3c',
              ]

            },
          ]
        })

        setLoading(false)
        setShowInfo('showZulia')
        break;

      case 'Limpiar':
        setLoading(true)
        setShowInfo('')
        setLoading(false)
        break;

      default:
        setLoading(true)
        setShowInfo('')
        setLoading(false)
        break;
    }
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-cobranzas"></span>
              </div>
              </> :
              <>
                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  {/* <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow' /> */}
                  <br />
                  <img src={notFoundImg} className="mx-auto w-100" />
                </div>

                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row">
                      <div className="col">
                        <div className="belmeny-text">
                          <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                          <h3>{user.Nombre}</h3>
                          <h5><i>{username}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow' />
                      </div>
                    </div>

                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Información de Cobranzas por Zona</h4>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-2">
                          <DropdownButton
                            key={'bottom'}
                            id={`dropdown-button-drop-${'bottom'}`}
                            drop={'bottom'}
                            variant="primary"
                            title={` Zona `}
                          >
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Andina')}>Andina</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Centro')}>Centro</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Falcon')}>Falcon</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Gran Caracas')}>Gran Caracas</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Llanos')}>Llanos</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Oriente')}>Oriente</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Tachira')}>Tachira</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Zulia')}>Zulia</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="1" className='text-decoration-none bg-danger text-light' onClick={e => handleFilter(e, 'Limpiar')}>Limpiar Filtro</Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                    </div>

                    {
                      (showInfo === 'showAndina') ?
                        <>
                          <div className="w-75 m-auto pt-4">
                            <h4 className="text-center belmeny-text fst-italic">Cobranzas Andina</h4>
                            <div className="row">
                              <div className="col">
                                <Chart type='pie' data={chartAndina2Month} options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                    },
                                    title: {
                                      display: true,
                                      text: 'Información sobre cobranzas',
                                    },
                                  },
                                }} className='my-4' />
                                <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                              </div>
                              <div className="col">
                                <Chart type='pie' data={chartAndina1Month} options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                    },
                                    title: {
                                      display: true,
                                      text: 'Información sobre cobranzas',
                                    },
                                  },
                                }} className='my-4' />
                                <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                              </div>
                              <div className="col">
                                <Chart type='pie' data={chartAndina} options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                    },
                                    title: {
                                      display: true,
                                      text: 'Información sobre cobranzas',
                                    },
                                  },
                                }} className='my-4' />
                                <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="division mt-4"></div>
                        </> :
                        (showInfo === 'showCentro') ?
                          <>
                            <div className="w-75 m-auto pt-4">
                              <h4 className="text-center belmeny-text fst-italic">Cobranzas Centro</h4>
                              <div className="row">
                                <div className="col">
                                  <Chart type='pie' data={chartCentro2Month} options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                        text: 'Información sobre cobranzas',
                                      },
                                    },
                                  }} className='my-4' />
                                  <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                </div>
                                <div className="col">
                                  <Chart type='pie' data={chartCentro1Month} options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                        text: 'Información sobre cobranzas',
                                      },
                                    },
                                  }} className='my-4' />
                                  <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                </div>
                                <div className="col">
                                  <Chart type='pie' data={chartCentro} options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: 'top',
                                      },
                                      title: {
                                        display: true,
                                        text: 'Información sobre cobranzas',
                                      },
                                    },
                                  }} className='my-4' />
                                  <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="division mt-4"></div>
                          </> :
                          (showInfo === 'showFalcon') ?
                            <>
                              <div className="w-75 m-auto pt-4">
                                <h4 className="text-center belmeny-text fst-italic">Cobranzas Falcon</h4>
                                <div className="row">
                                  <div className="col">
                                    <Chart type='pie' data={chartFalcon2Month} options={{
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                          text: 'Información sobre cobranzas',
                                        },
                                      },
                                    }} className='my-4' />
                                    <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                  </div>
                                  <div className="col">
                                    <Chart type='pie' data={chartFalcon1Month} options={{
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                          text: 'Información sobre cobranzas',
                                        },
                                      },
                                    }} className='my-4' />
                                    <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                  </div>
                                  <div className="col">
                                    <Chart type='pie' data={chartFalcon} options={{
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: 'top',
                                        },
                                        title: {
                                          display: true,
                                          text: 'Información sobre cobranzas',
                                        },
                                      },
                                    }} className='my-4' />
                                    <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="division mt-4"></div>
                            </> :
                            (showInfo === 'showLlanos') ?
                              <>
                                <div className="w-75 m-auto pt-4">
                                  <h4 className="text-center belmeny-text fst-italic">Cobranzas Llanos</h4>
                                  <div className="row">
                                    <div className="col">
                                      <Chart type='pie' data={chartLlanos2Month} options={{
                                        responsive: true,
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: true,
                                            text: 'Información sobre cobranzas',
                                          },
                                        },
                                      }} className='my-4' />
                                      <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                    </div>
                                    <div className="col">
                                      <Chart type='pie' data={chartLlanos1Month} options={{
                                        responsive: true,
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: true,
                                            text: 'Información sobre cobranzas',
                                          },
                                        },
                                      }} className='my-4' />
                                      <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                    </div>
                                    <div className="col">
                                      <Chart type='pie' data={chartLlanos} options={{
                                        responsive: true,
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: true,
                                            text: 'Información sobre cobranzas',
                                          },
                                        },
                                      }} className='my-4' />
                                      <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="division mt-4"></div>
                              </> :
                              (showInfo === 'showCaracas') ?
                                <>
                                  <div className="w-75 m-auto pt-4">
                                    <h4 className="text-center belmeny-text fst-italic">Cobranzas Caracas</h4>
                                    <div className="row">
                                      <div className="col">
                                        <Chart type='pie' data={chartCaracas2Month} options={{
                                          responsive: true,
                                          plugins: {
                                            legend: {
                                              position: 'top',
                                            },
                                            title: {
                                              display: true,
                                              text: 'Información sobre cobranzas',
                                            },
                                          },
                                        }} className='my-4' />
                                        <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                      </div>
                                      <div className="col">
                                        <Chart type='pie' data={chartCaracas1Month} options={{
                                          responsive: true,
                                          plugins: {
                                            legend: {
                                              position: 'top',
                                            },
                                            title: {
                                              display: true,
                                              text: 'Información sobre cobranzas',
                                            },
                                          },
                                        }} className='my-4' />
                                        <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                      </div>
                                      <div className="col">
                                        <Chart type='pie' data={chartCaracas} options={{
                                          responsive: true,
                                          plugins: {
                                            legend: {
                                              position: 'top',
                                            },
                                            title: {
                                              display: true,
                                              text: 'Información sobre cobranzas',
                                            },
                                          },
                                        }} className='my-4' />
                                        <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="division mt-4"></div>
                                </> :
                                (showInfo === 'showOriente') ?
                                  <>
                                    <div className="w-75 m-auto pt-4">
                                      <h4 className="text-center belmeny-text fst-italic">Cobranzas Oriente</h4>
                                      <div className="row">
                                        <div className="col">
                                          <Chart type='pie' data={chartOriente2Month} options={{
                                            responsive: true,
                                            plugins: {
                                              legend: {
                                                position: 'top',
                                              },
                                              title: {
                                                display: true,
                                                text: 'Información sobre cobranzas',
                                              },
                                            },
                                          }} className='my-4' />
                                          <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                        </div>
                                        <div className="col">
                                          <Chart type='pie' data={chartOriente1Month} options={{
                                            responsive: true,
                                            plugins: {
                                              legend: {
                                                position: 'top',
                                              },
                                              title: {
                                                display: true,
                                                text: 'Información sobre cobranzas',
                                              },
                                            },
                                          }} className='my-4' />
                                          <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                        </div>
                                        <div className="col">
                                          <Chart type='pie' data={chartOriente} options={{
                                            responsive: true,
                                            plugins: {
                                              legend: {
                                                position: 'top',
                                              },
                                              title: {
                                                display: true,
                                                text: 'Información sobre cobranzas',
                                              },
                                            },
                                          }} className='my-4' />
                                          <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="division mt-4"></div>
                                  </> :
                                  (showInfo === 'showTachira') ?
                                    <>
                                      <div className="w-75 m-auto pt-4">
                                        <h4 className="text-center belmeny-text fst-italic">Cobranzas Tachira</h4>
                                        <div className="row">
                                          <div className="col">
                                            <Chart type='pie' data={chartTachira2Month} options={{
                                              responsive: true,
                                              plugins: {
                                                legend: {
                                                  position: 'top',
                                                },
                                                title: {
                                                  display: true,
                                                  text: 'Información sobre cobranzas',
                                                },
                                              },
                                            }} className='my-4' />
                                            <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                          </div>
                                          <div className="col">
                                            <Chart type='pie' data={chartTachira1Month} options={{
                                              responsive: true,
                                              plugins: {
                                                legend: {
                                                  position: 'top',
                                                },
                                                title: {
                                                  display: true,
                                                  text: 'Información sobre cobranzas',
                                                },
                                              },
                                            }} className='my-4' />
                                            <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                          </div>
                                          <div className="col">
                                            <Chart type='pie' data={chartTachira} options={{
                                              responsive: true,
                                              plugins: {
                                                legend: {
                                                  position: 'top',
                                                },
                                                title: {
                                                  display: true,
                                                  text: 'Información sobre cobranzas',
                                                },
                                              },
                                            }} className='my-4' />
                                            <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="division mt-4"></div>
                                    </> :
                                    (showInfo === 'showZulia') ?
                                      <>
                                        <div className="w-75 m-auto pt-4">
                                          <h4 className="text-center belmeny-text fst-italic">Cobranzas Zulia</h4>
                                          <div className="row">
                                            <div className="col">
                                              <Chart type='pie' data={chartZulia2Month} options={{
                                                responsive: true,
                                                plugins: {
                                                  legend: {
                                                    position: 'top',
                                                  },
                                                  title: {
                                                    display: true,
                                                    text: 'Información sobre cobranzas',
                                                  },
                                                },
                                              }} className='my-4' />
                                              <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{twoMonthsName}</h6>
                                            </div>
                                            <div className="col">
                                              <Chart type='pie' data={chartZulia1Month} options={{
                                                responsive: true,
                                                plugins: {
                                                  legend: {
                                                    position: 'top',
                                                  },
                                                  title: {
                                                    display: true,
                                                    text: 'Información sobre cobranzas',
                                                  },
                                                },
                                              }} className='my-4' />
                                              <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{oneMonthsName}</h6>
                                            </div>
                                            <div className="col">
                                              <Chart type='pie' data={chartZulia} options={{
                                                responsive: true,
                                                plugins: {
                                                  legend: {
                                                    position: 'top',
                                                  },
                                                  title: {
                                                    display: true,
                                                    text: 'Información sobre cobranzas',
                                                  },
                                                },
                                              }} className='my-4' />
                                              <h6 className='text-center' style={{ textTransform: 'capitalize' }}>{monthsName}</h6>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="division mt-4"></div>
                                      </> :
                                      <>
                                        <h4 className="text-center belmeny-text fst-italic">Escoja una zona para mostrar su informacion de cobranzas</h4>
                                      </>
                    }
                  </div>
                </div>
              </>
          }

        </div>
      </Layout>
    </>
  )
}
