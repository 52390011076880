import React, { useState, useEffect } from 'react'

import AuthUser from '../../../components/AuthUser';
import { ItemsCobranzas } from './ItemsCobranzas';
import { ItemsCobranzasMobile } from './ItemsCobranzasMobile';
import { CobranzaPDF } from '../../PDF/CobranzaPDF';

import { getVendedor } from '../../../api/request';
import { getDetallesCobranzas } from '../../../api/requestCobranzas';

import { Modal } from 'react-bootstrap';
import { Dropdown, ButtonToolbar } from 'rsuite';
import { RiCloseCircleFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";

export const DetalleCobranzas = (props) => {

  var Cobranzas = props.item;

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [detalleCobranza, setDetalleCobranza] = useState([]);
  const [vendedor, setVendedor] = useState('');
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadDetallesCobranzas();
  }

  const loadDetallesCobranzas = async () => {
    setLoading(true)
    const res = await getDetallesCobranzas(Cobranzas.Documento);
    const vendedor = await getVendedor(Cobranzas.Usuario)
    setVendedor(vendedor[0].Nombre)

    setLoading(true)

    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setNextPage(res.links.next)
    setPrevPage(res.links.prev)
    setFirstPage(res.links.first)
    setLastPage(res.links.last)

    setDetalleCobranza(res.data)
    setLoading(false)
  }

  return (
    <>
      <tr className='d-none-table'>
        <td className='text-danger fw-bold text-center'>{Cobranzas.Documento}</td>
        <td>{Cobranzas.NombreCliente}</td>
        {(user.CodGerente !== null) ? <td className='d-md-block-table d-none-table fw-bold belmeny-text text-center'>{Cobranzas.Nombre}</td> : null}
        <td className='text-success fw-bold text-center'>${Cobranzas.TotalCobranza}</td>
        <td className='d-mb-block-table d-none-table text-center'>{new Date(Cobranzas.FechaCobranza).toLocaleDateString('es-MX')}</td>
        <td className='text-center'>
          <button className="btn btn-success btn-sm my-2 w-100" onClick={() => handleShow()}>Ver detalles</button>
          <CobranzaPDF user={user} Cobranzas={Cobranzas} />
        </td>
      </tr>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title belmeny-text">{Cobranzas.NombreCliente.toUpperCase()}</h5>
              </div>
              <div className="col-3">
                <ButtonToolbar className=''>
                  <Dropdown title={<SlOptionsVertical />} activeKey="e-2" className="border-belmeny">
                    <Dropdown.Item eventKey="details"><AiFillEye className='belmeny-text fs-2' onClick={() => handleShow()} /></Dropdown.Item>
                    <Dropdown.Item eventKey="report"><CobranzaPDF user={user} Cobranzas={Cobranzas} /></Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">{Cobranzas.Documento}</h6>
            {(user.CodGerente !== null) ? <h6 className="card-subtitle mb-2 belmeny-text fst-italic">{Cobranzas.Nombre}</h6> : null}
            <h6>Monto: <span className="text-success fw-bold fs-6">${Cobranzas.TotalCobranza.toFixed(2)}</span></h6>
            <h6 className='my-1'>Fecha: <span className=''>{new Date(Cobranzas.FechaCobranza).toLocaleDateString('es-MX')}</span></h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información de la cobranza: {Cobranzas.Documento}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">
            {
              (loading) ?
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span className="loader-cobranzas"></span>
                </div> :
                <>
                  {/* Body for desktop version */}
                  <div className='d-none d-md-block'>
                    <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Vendedor Encargado: {vendedor}</h3>
                    <div className="row badge-vendedor rounded-pill">
                      <div className="col">
                        <h4 className='text-center'>RIF: {Cobranzas.CodCliente}</h4>
                        <h3 className='text-center'>{Cobranzas.NombreCliente}</h3>
                      </div>
                    </div>

                    <div className="container mt-3">
                      <div className="row text-center">
                        <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Detalles de Cobranza</h3>
                      </div>

                      <div className="row my-3 text-center">
                        <div className="col">
                          <h5>Documento afectado: <span className="text-danger">{Cobranzas.DocumentoAfectado}</span></h5>
                        </div>
                        <div className="col">
                          <h5>Responsable: {(Cobranzas.Responsable === '') ? <>N/A</> : <>{Cobranzas.Responsable}</>}</h5>
                        </div>
                        <div className="col">
                          <h5>Comentarios: {(Cobranzas.Comentarios === '') ? <>No se agregaron comentarios</> : <>{Cobranzas.Comentarios}</>}</h5>
                        </div>
                      </div>

                      <div className="division bg-belmeny my-2"></div>

                      <table className='table table-responsive table-bordered table-hover border-belmeny my-3 fs-5 text-center'>
                        <thead className='bg-belmeny text-light'>
                          <tr>
                            <th>Referencia</th>
                            <th>Forma de Pago</th>
                            <th>Banco</th>
                            <th>Fecha del Pago</th>
                            <th>Monto</th>
                            <th>Recibo</th>
                            <th>Tasa</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detalleCobranza.map((item) => <ItemsCobranzas item={item} key={item.DocumentoFormaPago} />)}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Body for mobile version */}
                  <div className='d-block d-sm-none'>
                    <h5 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3">Vendedor Encargado: {user.Nombre}</h5>

                    <div className="row badge-vendedor rounded-pill">
                      <h3 className='text-center fs-2'>RIF: {Cobranzas.CodCliente}</h3>
                      <h4 className='text-center fs-4'>{Cobranzas.NombreCliente}</h4>
                    </div>

                    <div className="container mt-3">
                      <div className="row text-center">
                        <h4 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3 fw-normal">Detalles de Cobranza</h4>
                      </div>

                      <div className="row my-3 text-center">
                        <h5>Documento afectado: {Cobranzas.DocumentoAfectado}</h5>
                        <h5 className='my-2'>Responsable: {(Cobranzas.Responsable === '') ? <>N/A</> : <>{Cobranzas.Responsable}</>}</h5>
                        <h5>Comentarios: {(Cobranzas.Comentarios === '') ? <>No se agregaron comentarios</> : <>{Cobranzas.Comentarios}</>}</h5>
                      </div>

                      <div className="division bg-belmeny my-2"></div>

                      <div className="row text-center mb-2">
                        <div className="col">
                          <h6 className='fs-6'>Referencia</h6>
                        </div>
                        <div className="col">
                          <h6 className='fs-6'>Forma de Pago</h6>
                        </div>
                        <div className="col">
                          <h6 className='fs-6'>Banco</h6>
                        </div>
                      </div>
                      {detalleCobranza.map((item) => <ItemsCobranzasMobile item={item} unaFila={true} key={item.DocumentoFormaPago + "unaFila"} />)}
                      <div className="bg-belmeny division my-2"></div>
                      <div className="row text-center mb-2">
                        <div className="col">
                          <h6 className="fs-6">Fecha del Pago</h6>
                        </div>
                        <div className="col">
                          <h6 className="fs-6">Monto</h6>
                        </div>
                        <div className="col">
                          <h6 className="fs-6">Recibo</h6>
                        </div>
                      </div>
                      {detalleCobranza.map((item) => <ItemsCobranzasMobile item={item} unaFila={false} segundaFila={true} key={item.DocumentoFormaPago + "segundaFila"} />)}
                      <div className="bg-belmeny division my-2"></div>
                      <div className="row text-center mb-2">
                        <div className="col">
                          <h6 className="fs-6">Tasa</h6>
                        </div>
                      </div>
                      {detalleCobranza.map((item) => <ItemsCobranzasMobile item={item} unaFila={false} segundaFila={false} terceraFila={true} key={item.DocumentoFormaPago + "terceraFila"} />)}
                    </div>
                  </div>
                </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
