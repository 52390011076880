import React from 'react'

import { formatNumber } from '../../utils/formats';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'
import coronaSvg from '../../assets/img/logos/CORONA-SVG.svg'
import wadfowSvg from '../../assets/img/logos/WADFOW-SVG.svg'

export const TopProductosSupervisor = ({ totalVendido, ventasProductos, totalVendidoVert, totalVendidoIngco, totalVendidoQuilosa, totalVendidoImou, totalVendidoFleximatic, totalVendidoCorona, totalVendidoWadfow }) => {

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const sumTotalVendido = totalVendidoVert + totalVendidoIngco + totalVendidoQuilosa + totalVendidoImou + totalVendidoFleximatic + totalVendidoCorona + totalVendidoWadfow

  return (
    <div className="row">
      <div className="col">
        <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
          <h4 className="belmeny-text mb-3">Ventas por marca</h4>
          <h5 className="belmeny-text mb-3">Total vendido en el mes: <span className="text-success fw-bold">${formatNumber(sumTotalVendido.toFixed(2))}</span></h5>
          <div className="container-fluid h-100">
            <div className="row bg-belmeny3 text-white py-3 rounded-top">
              <div className="col-3">
                <h5 className="text-uppercase">Marca</h5>
              </div>
              <div className="col">
                <h5 className="text-uppercase text-center">Productos Vendidos</h5>
              </div>
              <div className="col">
                <h5 className="text-uppercase text-center">Ventas Generadas</h5>
              </div>
              <div className="col">
                <h5 className="text-uppercase text-center">% Representado</h5>
              </div>
            </div>
            {/* VERT */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosVert === null || ventasProductos[0].CantidadProductosVert === 0) ? 0 : ventasProductos[0].CantidadProductosVert
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                  {
                    (totalVendidoVert === null || totalVendidoVert === 0) ? 0 : formatNumber(totalVendidoVert.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                  {
                    (totalVendidoVert === null || totalVendidoVert === 0) ? 0 : formatNumber(((totalVendidoVert * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* INGCO */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-2 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosIngco === null || ventasProductos[0].CantidadProductosIngco === 0) ? 0 : ventasProductos[0].CantidadProductosIngco
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                  {
                    (totalVendidoIngco === null || totalVendidoIngco === 0) ? 0 : formatNumber(totalVendidoIngco.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                  {
                    (totalVendidoIngco === null || totalVendidoIngco === 0) ? 0 : formatNumber(((totalVendidoIngco * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* QUILOSA */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosQuilosa === null || ventasProductos[0].CantidadProductosQuilosa === 0) ? 0 : ventasProductos[0].CantidadProductosQuilosa
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                  {
                    (totalVendidoQuilosa === null || totalVendidoQuilosa === 0) ? 0 : formatNumber(totalVendidoQuilosa.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                  {
                    (totalVendidoQuilosa === null || totalVendidoQuilosa === 0) ? 0 : formatNumber(((totalVendidoQuilosa * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* IMOU */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosImou === null || ventasProductos[0].CantidadProductosImou === 0) ? 0 : ventasProductos[0].CantidadProductosImou
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                  {
                    (totalVendidoImou === null || totalVendidoImou === 0) ? 0 : formatNumber(totalVendidoImou.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                  {
                    (totalVendidoImou === null || totalVendidoImou === 0) ? 0 : formatNumber(((totalVendidoImou * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* FLEXIMATIC */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosFleximatic === null || ventasProductos[0].CantidadProductosFleximatic === 0) ? 0 : ventasProductos[0].CantidadProductosFleximatic
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                  {
                    (totalVendidoFleximatic === null || totalVendidoFleximatic === 0) ? 0 : formatNumber(totalVendidoFleximatic.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                  {
                    (totalVendidoFleximatic === null || totalVendidoFleximatic === 0) ? 0 : formatNumber(((totalVendidoFleximatic * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* CORONA */}
            <div className="row mt-3">
              <div className="col-3">
                <h5 className="text-uppercase pt-1"><img src={coronaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosCorona === null || ventasProductos[0].CantidadProductosCorona === 0) ? 0 : ventasProductos[0].CantidadProductosCorona
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal text-success">
                  {
                    (totalVendidoCorona === null || totalVendidoCorona === 0) ? 0 : formatNumber(totalVendidoCorona.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal text-info">
                  {
                    (totalVendidoCorona === null || totalVendidoCorona === 0) ? 0 : formatNumber(((totalVendidoCorona * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
            {/* WADFOW */}
            <div className="row mt-4">
              <div className="col-3">
                <h5 className="text-uppercase pt-2"><img src={wadfowSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal">
                  {
                    (ventasProductos[0].CantidadProductosWadfow === null || ventasProductos[0].CantidadProductosWadfow === 0) ? 0 : ventasProductos[0].CantidadProductosWadfow
                  }
                  <span className="ps-1">Productos</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal text-success">
                  {
                    (totalVendidoWadfow === null || totalVendidoWadfow === 0) ? 0 : formatNumber(totalVendidoWadfow.toFixed(2))
                  }
                  <span className="ps-1">USD</span></h5>
              </div>
              <div className="col">
                <h5 className="text-center text-uppercase pt-3 fw-normal text-info">
                  {
                    (totalVendidoWadfow === null || totalVendidoWadfow === 0) ? 0 : formatNumber(((totalVendidoWadfow * 100) / sumTotalVendido).toFixed(2))
                  }%
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
