import React, { useState, useEffect } from 'react'

import Pagination from 'react-responsive-pagination';

import { RequestPage } from '../../api/request';
import { getParetos } from '../../api/requestSupervisor';
import { TopClientesXLS } from '../../views/XLS/TopClientesXLS';

import { formatNumber } from '../../utils/formats';

export const TopClientes = ({ topClientes, supervisor, loaded }) => {

  const [loading, setLoading] = useState(true);

  const [clientes, setClientes] = useState(topClientes.data)

  // Paginación
  // eslint-disable-next-line
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState(topClientes.last_page);
  const [urlPage, setUrlPage] = useState(topClientes.first_page_url);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (supervisor) {
      loadPage(urlPage.replace(/.$/, page + "&CodSupervisor=" + supervisor))
    } else {
      loadPage(urlPage.replace(/.$/, page))
    }
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const clientesData = await RequestPage(pageData);

    setClientes(clientesData.data)
    setPage(clientesData.current_page)
    setCurrentPage(clientesData.current_page)
    setTotalPages(clientesData.last_page)
    setUrlPage(clientesData.first_page_url)

    setLoading(false)
  }

  const loadParetosData = async (supervisor) => {
    if (loaded === "todos") return
    setLoading(true)
    let requestParetos;
    if (supervisor) {
      requestParetos = await getParetos(supervisor, null, null)
    } else {
      requestParetos = await getParetos(null, null, null)
    }

    setClientes(requestParetos.data)
    setTotalPages(requestParetos.last_page)
    setUrlPage(requestParetos.first_page_url)
  }

  useEffect(() => {
    loadParetosData(supervisor)
    setLoading(false)
  }, [])

  return (
    <>
      {
        (loading) ?
          <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <div className="loader-book">
              <div>
                <ul>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                  <li>
                    <svg fill="currentColor" viewBox="0 0 90 120">
                      <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                    </svg>
                  </li>
                </ul>
              </div>
              <span>Cargando estadisticas...</span>
            </div>
          </div>
          :
          <>
            <TopClientesXLS generate={loaded} />
            <div className="row mt-3">
              <div className="col bg-white rounded drop-shadow-card">
                <h4 className='belmeny-text pt-2 ps-1'>Datos cargados: <span className="text-uppercase">{loaded}</span></h4>
                <div className="container-fluid mx-auto row bg-belmeny3 text-light mt-3 py-3 rounded-top">
                  <div className="col-2">
                    <h5 className="text-uppercase">ZONA</h5>
                  </div>
                  <div className="col">
                    <h5 className="text-uppercase text-center">CLIENTE</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-uppercase text-center">VENDEDOR</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-uppercase text-center">MÁXIMO FACTURADO</h5>
                  </div>
                  <div className="col-2">
                    <h5 className="text-uppercase text-center">FACTURADO</h5>
                  </div>
                </div>

                {
                  (clientes.length > 0) ?
                    (clientes.map((cliente, idx) =>
                      <>
                        <div className="row my-2 px-3 belmeny-text" key={idx}>
                          <div className="col-2">
                            <h5 className="">{cliente.Sector}</h5>
                          </div>
                          <div className="col">
                            <h6 className='text-truncate' style={{ maxWidth: "450px" }}>{cliente.NombreCliente}</h6>
                          </div>
                          <div className="col-2 text-center">
                            <h6 className=''>{cliente.NombreVendedor}</h6>
                          </div>
                          <div className="col-2 my-auto text-center">
                            <h6 className='text-success'>{formatNumber(cliente.Maximo_Facturado.toFixed(2))} USD</h6>
                            <h6 className='text-muted fw-normal fst-italic text-center'>{cliente.Mes}</h6>
                          </div>
                          <div className="col-2 my-auto text-center">
                            <h6 className={cliente.Facturado < cliente.Maximo_Facturado * 0.4 ? 'text-danger' : (cliente.Facturado < cliente.Maximo_Facturado * 0.6 ? 'text-warning' : 'text-success')}>
                              {formatNumber(cliente.Facturado.toFixed(2))} USD
                            </h6>
                            <h6 className='text-muted fw-normal fst-italic text-center'>Mes en curso</h6>
                          </div>
                        </div>
                      </>
                    )) : <h4 className="text-center belmeny-text fst-italic mt-3 alert alert-info">No hay información de facturación de clientes por el momento...</h4>
                }
                <div className="division mt-4 mb-2"></div>
                <Pagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={page => handlePageChange(page)} />
              </div>
            </div>
          </>
      }
    </>
  )
}