import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleCobranzas } from './DetalleCobranzas';

import { RequestPage } from '../../../api/request';
import { getCobranzasxCliente, getAllCobranzas, SearchCobranzas } from '../../../api/requestCobranzas';

import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { DateRangePicker, Stack } from 'rsuite';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const ConsultaCobranzas = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchbarVend, setSearchbarVend] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  // States de busqueda
  const [term, setTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('')
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByVendedor, setFoundByVendedor] = useState(false);
  const [inicio, setInicio] = useState('');
  const [fin, setFin] = useState('');

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  // States para los datos
  const [Cobranzas, setCobranzas] = useState([]);

  // Rango de fechas
  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  useEffect(() => {
    loadCobranzas();
  }, []);

  const loadCobranzas = async () => {
    setLoading(true);

    var res;
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      res = await getCobranzasxCliente(user.CodVendedor);
    } else if (user.CodSupervisor === null || user.CodGerente !== null) {
      res = await getAllCobranzas(user.CodVendedor);
    }

    setCobranzas(res.data)
    setPage(res.current_page)
    setTotalPages(res.last_page)
    setUrlPage(res.first_page_url)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const cobranzasData = await RequestPage(pageData);

    if (searchTerm) {
      setPage(cobranzasData.current_page)
      setTotalPages(cobranzasData.last_page)
      setUrlPage(cobranzasData.first_page_url)
    } else {
      if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
        setPage(cobranzasData.current_page)
        setTotalPages(cobranzasData.last_page)
        setUrlPage(cobranzasData.first_page_url)
      } else if (user.CodSupervisor === null || user.CodGerente !== null) {
        setPage(cobranzasData.current_page)
        setTotalPages(cobranzasData.last_page)
        setUrlPage(cobranzasData.first_page_url)
      }
    }

    setCobranzas(cobranzasData.data)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      if (searchTerm) {
        switch (searchTerm) {
          case 'Fecha':
            loadPage(urlPage.replace(/.$/, page + `&Usuario=${user.CodVendedor}&fechaInicio=${inicio}&fechaFin=${fin}&whatToSearch=${searchTerm}`))
            break;

          default:
            loadPage(urlPage.replace(/.$/, page + `&Usuario=${user.CodVendedor}&Busqueda=${term}&whatToSearch=${searchTerm}`))
            break;
        }
      } else {
        loadPage(urlPage.replace(/.$/, page) + "&Usuario=" + user.CodVendedor)
      }
    } else if (user.CodSupervisor === null || user.CodGerente !== null) {
      if (searchTerm) {
        switch (searchTerm) {
          case 'Fecha':
            loadPage(urlPage.replace(/.$/, page + `&Usuario=${user.CodVendedor}&fechaInicio=${inicio}&fechaFin=${fin}&whatToSearch=${searchTerm}`))
            break;

          default:
            loadPage(urlPage.replace(/.$/, page + `&Usuario=${user.CodVendedor}&Busqueda=${term}&whatToSearch=${searchTerm}`))
            break;
        }
      } else {
        loadPage(urlPage.replace(/.$/, page + "&Usuario=" + user.CodVendedor))
      }
    }
  }

  const onSearch = async (busqueda) => {
    setLoading(true);

    if (!busqueda) {
      setSearchTerm(null);
      return loadCobranzas();
    } else {
      setSearching(true);

      const searchResult = await SearchCobranzas(user.CodVendedor, searchTerm, busqueda, null, null);

      setTerm(busqueda);
      setNotFound(!searchResult || searchResult.data.length === 0);
      setCurrentPage(searchResult ? searchResult.current_page : 1);
      setPage(searchResult ? searchResult.current_page : 1);
      setTotalPages(searchResult ? searchResult.last_page : 1);
      setCobranzas(searchResult ? searchResult.data : null);
      setUrlPage(searchResult ? searchResult.first_page_url : null);

      setSearching(false);
      setLoading(false);
    }
  };

  const changeDate = async (e) => {
    setLoading(true)
    let date = JSON.stringify(e)
    let start = date.slice(2, 12) + " " + date.slice(14, 21)
    let end = date.slice(29, 39) + " " + date.slice(41, 48)

    const searchResult = await SearchCobranzas(user.CodVendedor, searchTerm, null, start, end);
    setInicio(start)
    setFin(end)

    setNotFound((searchResult.data.length <= 0) ? true : false)
    setCobranzas((searchResult.data.length > 0) ? searchResult.data : null)
    setCurrentPage((searchResult.data.length > 0) ? searchResult.current_page : 1)
    setPage((searchResult.data.length > 0) ? searchResult.current_page : 1);
    setTotalPages((searchResult.data.length > 0) ? searchResult.last_page : 1);
    setUrlPage((searchResult.data.length > 0) ? searchResult.first_page_url : '')

    setLoading(false)
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    setSearchTerm(filtro);

    const filters = {
      'Documento': { setSearchbarDoc: 'd-block', setSearchbarCli: 'd-none', setSearchbarVend: 'd-none', setSearchDate: 'd-none' },
      'Cliente': { setSearchbarDoc: 'd-none', setSearchbarCli: 'd-block', setSearchbarVend: 'd-none', setSearchDate: 'd-none' },
      'Vendedor': { setSearchbarDoc: 'd-none', setSearchbarCli: 'd-none', setSearchbarVend: 'd-block', setSearchDate: 'd-none' },
      'Fecha': { setSearchbarDoc: 'd-none', setSearchbarCli: 'd-none', setSearchbarVend: 'd-none', setSearchDate: 'd-block' },
      'Limpiar': { onSearch: '', setSearchbarDoc: 'd-none', setSearchbarCli: 'd-none', setSearchbarVend: 'd-none', setSearchDate: 'd-none', setFoundByFecha: false, setSearchTerm: '' },
      'default': { onSearch: '', setSearchbarDoc: 'd-none', setSearchbarCli: 'd-none', setSearchbarVend: 'd-none', setSearchDate: 'd-none', setFoundByFecha: false, setSearchTerm: '' }
    };

    const filterSettings = filters[filtro] || filters['default'];
    Object.entries(filterSettings).forEach(([key, value]) => key && eval(`${key}('${value}')`));
  }

  return (
    <>
      <Layout>
        {/* Cuerpo de página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-cobranzas"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Cobranzas</h4>
              </div>

              <h5 className="text-center mt-2 mb-4 belmeny-text"><i>
                {
                  (user.CodGerente !== null) ?
                    <>
                      Supervisor / Gerente - {user.Nombre} - {user.CodVendedor}
                    </> :
                    <>
                      Vendedor - {user.Nombre} - {user.CodVendedor}
                    </>
                }
              </i></h5>

              {/* Desktop */}
              <div className="container-fluid d-md-block d-none mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Documento')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        {(user.CodGerente !== null) ?
                            <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Vendedor')}>Por vendedor</Dropdown.Item>: null}
                        <Dropdown.Item eventKey="4" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="5" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarVend}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                        disabledDate={afterToday()}
                      />
                    </Stack>
                  </div>
                </div>

                <div className="rounded-0 my-2">
                  {
                    (notFound) ?
                      <>
                        <div className="alert alert-danger text-center" role="alert">
                          <h5>No se encontraron resultados</h5>
                        </div>
                      </> :
                      <>
                        <table className='table table-bordered table-hover border-belmeny'>
                          <thead className='bg-belmeny text-light text-center'>
                            <tr>
                              <th>Cobranza</th>
                              <th className='w-50'>Cliente</th>
                              {(user.CodGerente !== null) ? <th>Vendedor</th> : null}
                              <th>Monto</th>
                              <th>Fecha</th>
                              <th style={{ width: "15%" }}>Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Cobranzas.map((item) => <DetalleCobranzas item={item} key={item.Documento} />)}
                          </tbody>
                        </table>

                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      </>
                  }
                </div>
              </div>

              {/* Mobile */}
              <div className="container-fluid d-block d-sm-none">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        {(user.CodGerente !== null) ?
                          <>
                            <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Vendedor')}>Por vendedor</Dropdown.Item>
                          </> : null}
                        <Dropdown.Item eventKey="4" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="5" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarVend}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>
                {
                  (notFound) ?
                    <>
                      <div className="alert alert-danger text-center" role="alert">
                        <h5>No se encontraron resultados</h5>
                      </div>
                    </> :
                    <>
                      <div className="rounded-0 my-2">
                        {Cobranzas.map((item) => <DetalleCobranzas item={item} key={item.Documento + "Mobile"} />)}
                      </div>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
                <div className="mb-2"></div>
              </div>
            </>
        }
      </Layout>
    </>
  )
}
