import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
TRACKING
==================================================================================
*/
export async function getTrackingxVendedor(CodVendedor) {
  try {
    var res;
    if (CodVendedor === 'none') {
      res = await axios.get(baseURL + `/TrackPedidosVendedor`);
    } else {
      res = await axios.get(baseURL + `/TrackPedidosVendedor?CodVendedor[eq]=${CodVendedor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function SearchTracking(Documento) {
  try {
    const res = await axios.get(baseURL + `/TrackPedidosVendedor?Documento[eq]=${Documento}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function SearchTrackingbyFechas(CodVendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getTrackingPorFecha?CodVendedor=${CodVendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function SearchTrackingbyCliente(CodVendedor, NombreCliente) {
  try {
    const res = await axios.get(baseURL + `/getTrackingCliente?CodVendedor=${CodVendedor}&NombreCliente=${NombreCliente}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}