import React from 'react'

import brokenImg from '../../../assets/img/broken-image-general.png';

export const ProductosFacturaMobile = (props) => {

  var Productos = props.item

  const handleImageError = (event) => {
    event.target.src = brokenImg; // Replace with your default image URL
  };

  return (
    <>
      <div className="row">
        <h5 className="text-danger fw-bold text-center my-2">{Productos.Codigo}</h5>
        <h5 className="belmeny-text fw-bold text-center">{Productos.Nombre}</h5>
        <img src={`https://www.belmeny.com/catalogo/${Productos.Codigo}.png`} onError={handleImageError} alt="" className='w-50 border-belmeny rounded mx-auto my-3' />
        <h5>Precio: <span className="text-success">${Productos.PrecioUnitario.toFixed(2)}</span></h5>
        <h5>Cantidad: {Productos.Cantidad} UD</h5>
        <h5>Subtotal: <span className="text-success">${Productos.Subtotal.toFixed(2)}</span></h5>
      </div>

      <div className="division mt-2"></div>
    </>
  )
}
