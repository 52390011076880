import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleTrackingOperaciones } from './DetalleTrackingOperaciones';

import { getTrackingxVendedor, SearchTracking, SearchTrackingbyFechas, SearchTrackingbyCliente } from '../../../api/requestTracking';
import { RequestPage } from '../../../api/request';

import { Dropdown, DropdownButton } from 'react-bootstrap'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { DateRangePicker, Stack } from 'rsuite';

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';


export const ConsultaTrackingOperaciones = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  // States de busqueda
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [trackingFound, setTrackingFound] = useState([]);
  const [trackingFoundFecha, setTrackingFoundFecha] = useState([]);
  const [trackingFoundCliente, setTrackingFoundCliente] = useState([]);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  // States para los datos
  const [Tracking, setTracking] = useState([]);

  const predefinedRanges = [
    {
      label: 'Hoy',
      value: [new Date(), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Ayer',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'bottom'
    },
    {
      label: 'Esta semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'bottom'
    },
    {
      label: 'Últimos 7 días',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Últimos 30 días',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes actual',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes anterior',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Este año',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    },
    {
      label: 'Desde el comienzo',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Última semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Próxima semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];

  useEffect(() => {
    loadTracking();
    setLoading(true)
  }, []);

  const loadTracking = async () => {
    setLoading(true);
    const res = await getTrackingxVendedor('');
    // console.log(res)

    var arrTracking = []
    var data = {}

    for (let i = 0; i < res.data.length; i++) {
      data = {
        Documento: res.data[i].Documento,
        CodVendedor: res.data[i].CodVendedor,
        NombreCliente: res.data[i].NombreCliente,
        FechaCreacion: res.data[i].FechaCreacion,
        FinDepositario: res.data[i].FinDepositario,
        FinEmpacador: res.data[i].FinEmpacador,
        Facturacion: res.data[i].Facturacion,
        FechaEnvio: res.data[i].FechaEnvio,
        FechaAnulacion: res.data[i].FechaAnulacion,
        FechaSalida: res.data[i].FechaSalida,
        Cajas: res.data[i].Cajas,
        Bolsas: res.data[i].Bolsas,
        Estado: res.data[i].Estado,
      }
      arrTracking.push(data)
    }

    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setNextPage(res.links.next)
    setPrevPage(res.links.prev)
    setFirstPage(res.links.first)
    setLastPage(res.links.last)

    setTracking(arrTracking)
    setLoading(false)
  }

  const loadPage = async (e, pageData) => {
    setLoading(true)

    const trackingData = await RequestPage(pageData);

    var arrTracking = []
    var data = {}

    for (let i = 0; i < trackingData.data.length; i++) {
      data = {
        Documento: trackingData.data[i].Documento,
        CodVendedor: trackingData.data[i].CodVendedor,
        NombreCliente: trackingData.data[i].NombreCliente,
        FechaCreacion: trackingData.data[i].FechaCreacion,
        FinDepositario: trackingData.data[i].FinDepositario,
        FinEmpacador: trackingData.data[i].FinEmpacador,
        Facturacion: trackingData.data[i].Facturacion,
        FechaEnvio: trackingData.data[i].FechaEnvio,
        FechaAnulacion: trackingData.data[i].FechaAnulacion,
        FechaSalida: trackingData.data[i].FechaSalida,
        Cajas: trackingData.data[i].Cajas,
        Bolsas: trackingData.data[i].Bolsas,
        Estado: trackingData.data[i].Estado,
      }
      arrTracking.push(data)
    }

    setPage(trackingData.meta.current_page)
    setTotalPages(trackingData.meta.last_page)
    setNextPage(trackingData.links.next)
    setPrevPage(trackingData.links.prev)
    setFirstPage(trackingData.links.first)
    setLastPage(trackingData.links.last)

    setTracking(arrTracking)
    setLoading(false)
  }

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTrackingbyCliente('none', busqueda);
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return;
      } else {
        setFoundByCliente(true)
        setTrackingFoundCliente(result)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchbarDoc === 'd-block') {
      if (!busqueda) {
        setFound(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTracking(busqueda.toUpperCase());
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        return;
      } else {
        setFound(true)
        setTrackingFound(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchDate !== 'd-block') {
      setFoundByFecha(false)
    }
  };

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let inicio = date.slice(2, 12) + " " + date.slice(14, 21)
    let fin = date.slice(29, 39) + " " + date.slice(41, 48)

    const res = await SearchTrackingbyFechas('none', inicio, fin)
    // console.log(res)
    if (res.length === 0) {
      setFound(false)
      setFoundByFecha(false)
    }
    else {
      setFound(false)
      setFoundByFecha(true)
      setTrackingFoundFecha(res)
    }
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Doc':
        setSearchbarDoc('d-block')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
      case 'Cliente':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-block')
        setSearchDate('d-none')
        break;
      case 'Fecha':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-block')
        break;

      case 'Limpiar':
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        setFoundByFecha(false)
        onSearch('')
        break;

      default:
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  function format(inputDate) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
      .toString()
      .padStart(2, '0');

    month = month
      .toString()
      .padStart(2, '0');

    return `${date}/${month}/${year}`;
  }

  return (
    <>
      <Layout>


        {/* Cuerpo de página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-tracking"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Tracking de Pedidos</h4>
              </div>

              <h6 className="text-center mt-2 mb-4 belmeny-text"><i>Usuario - {user.Nombre} - {user.CodSupervisor}</i></h6>
              {/* Desktop */}
              <div className="container-fluid d-md-block d-none mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>
                <div className="rounded-0 my-2">
                  <table className='table table-bordered table-hover border-belmeny'>
                    <thead className='bg-belmeny text-light'>
                      <tr>
                        <th>Documento</th>
                        <th className='w-50'>Cliente</th>
                        <th>Fecha de ingreso al sistema</th>
                        <th>Estado</th>
                        <th style={{ width: "15%" }}>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (found) ?
                          <>
                            {/* Encontrado unicamente por barra de busqueda */}
                            {trackingFound.map((item) => <DetalleTrackingOperaciones item={item} found={found} />)}
                          </> :
                          (foundByFecha) ?
                            <>
                              {/* Encontrado por fechas */}
                              {trackingFoundFecha.map((item) => <DetalleTrackingOperaciones item={item} found={found} foundFecha={foundByFecha} />)}
                            </> :
                            (foundByCliente) ?
                              <>
                                {/* Encontrado por cliente */}
                                {trackingFoundCliente.map((item) => <DetalleTrackingOperaciones item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                              </> :
                              <>
                                {/* Carga normal de todos los pedidos */}
                                {Tracking.map((item) => <DetalleTrackingOperaciones item={item} found={found} />)}
                              </>
                      }
                    </tbody>
                  </table>
                </div>

                {
                  (foundByFecha) ?
                    <>
                      <button onClick={e => loadPage(e, firstPage)} className='btn btn-sm btn-primary me-2' disabled><AiOutlineDoubleLeft /></button>
                      {
                        (prevPage === null) ?
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button> :
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button>
                      }
                      <span className="ms-2">{page}</span> de <span className="me-2">1</span>
                      {
                        (nextPage === null) ?
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button> :
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button>
                      }
                      <button onClick={e => loadPage(e, lastPage)} className="btn btn-sm btn-primary ms-2" disabled><AiOutlineDoubleRight /></button>
                    </> :
                    <>
                      <button onClick={e => loadPage(e, firstPage)} className='btn btn-sm btn-primary me-2'><AiOutlineDoubleLeft /></button>
                      {
                        (prevPage === null) ?
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button> :
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary">
                            <AiOutlineLeft />
                          </button>
                      }
                      <span className="ms-2">{page}</span> de <span className="me-2">{totalPages}</span>
                      {
                        (nextPage === null) ?
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button> :
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" >
                            <AiOutlineRight />
                          </button>
                      }
                      <button onClick={e => loadPage(e, lastPage)} className="btn btn-sm btn-primary ms-2"><AiOutlineDoubleRight /></button>
                    </>
                }
              </div>

              {/* Mobile */}
              <div className="container-fluid d-block d-sm-none">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>

                {
                  (found) ?
                    <>
                      {/* Encontrado unicamente por barra de busqueda */}
                      {trackingFound.map((item) => <DetalleTrackingOperaciones item={item} found={found} />)}
                    </> :
                    (foundByFecha) ?
                      <>
                        {/* Encontrado por fechas */}

                        {trackingFoundFecha.map((item) => <DetalleTrackingOperaciones item={item} found={found} foundFecha={foundByFecha} />)}

                      </> :
                      (foundByCliente) ?
                        <>
                          {/* Encontrado por cliente */}
                          {trackingFoundCliente.map((item) => <DetalleTrackingOperaciones item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                        </> :
                        <>
                          {/* Carga normal de todos los pedidos */}
                          {Tracking.map((item) => <DetalleTrackingOperaciones item={item} found={found} />)}
                        </>
                }

                {
                  (foundByFecha) ?
                    <>
                      <button onClick={e => loadPage(e, firstPage)} className='btn btn-primary me-2' disabled><AiOutlineDoubleLeft /></button>
                      {
                        (prevPage === null) ?
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button> :
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button>
                      }
                      <span className="ms-2">{page}</span> de <span className="me-2">1</span>
                      {
                        (nextPage === null) ?
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button> :
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button>
                      }
                      <button onClick={e => loadPage(e, lastPage)} className="btn btn-primary ms-2" disabled><AiOutlineDoubleRight /></button>
                    </> :
                    <>
                      <button onClick={e => loadPage(e, firstPage)} className='btn btn-primary me-2'><AiOutlineDoubleLeft /></button>
                      {
                        (prevPage === null) ?
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                            <AiOutlineLeft />
                          </button> :
                          <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary">
                            <AiOutlineLeft />
                          </button>
                      }
                      <span className="ms-2">{page}</span> de <span className="me-2">{totalPages}</span>
                      {
                        (nextPage === null) ?
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                            <AiOutlineRight />
                          </button> :
                          <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" >
                            <AiOutlineRight />
                          </button>
                      }
                      <button onClick={e => loadPage(e, lastPage)} className="btn btn-primary ms-2"><AiOutlineDoubleRight /></button>
                    </>
                }
              </div>

              <div className="division bg-belmeny my-3 mx-auto" style={{ width: "95%" }}></div>
            </>
        }
      </Layout>
    </>
  )
}
