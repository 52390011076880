import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';

import { postNewVideo } from '../../../api/request'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'

// Moment.js for date uses
import moment from 'moment/moment';

// Sweetalert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const CrearTutorial = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);

  // State de datos
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [link, setLink] = useState("")

  // Date
  const today = moment().format("YYYY-M-D HH:mm:ss");

  // Sweetalert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const originalUrl = link;
      const encodedUrl = encodeURIComponent(originalUrl);

      const res = await postNewVideo(title, description, encodedUrl, today);

      if (res.status === 200) {
        customSwal.fire({
          title: "¡Vídeo registrado exitosamente!",
          icon: 'success',
          showConfirmButton: false,
          showLoaderOnConfirm: true,
          timer: 2000,
          timerProgressBar: true
        }).then((result) => {
          navigate('/tutorials')
        })
      }
    } catch (error) {
      console.error('Error al subir el vídeo:', error);
    } finally {
      setLoading(false);
    }
    // customSwal.fire({
    //   title: 'Registrar nuevo vídeo',
    //   text: '¿Estás seguro de querer registrar este vídeo?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonText: 'Registrar',
    //   cancelButtonText: 'Cancelar',
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     try {
    //       setLoading(true);
    //       const originalUrl = link;
    //       const encodedUrl = encodeURIComponent(originalUrl);

    //       const res = await postNewVideo(title, description, encodedUrl, today);

    //       if (res.status === 200) {
    //         customSwal.fire({
    //           title: "¡Vídeo registrado exitosamente!",
    //           icon: 'success',
    //           showConfirmButton: false,
    //           showLoaderOnConfirm: true,
    //           timer: 2000,
    //           timerProgressBar: true
    //         }).then((result) => {
    //           navigate('/tutorials')
    //         })
    //       }
    //     } catch (error) {
    //       console.error('Error al subir el vídeo:', error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   } else if (result.isDismissed) {
    //     // Nothing happens here if dismiss...
    //   }

    // }).catch((err) => {
    //   console.error(err)
    // })
  }

  return (
    <>
      <Layout>
        <div className='bg-dashboard bg-psh'>
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div className="loader-book">
                    <div>
                      <ul>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                      </ul>
                    </div>
                    <span className="text-dark">Cargando registro...</span>
                  </div>
                </div>
              </> :
              <>
                {/* Cabecera */}
                <div className="consultas-title mt-5">
                  <h4 className='bg-belmeny text-light px-5 rounded-pill'>Registro de vídeos</h4>
                </div>
                <h5 className="text-center mt-2 mb-4 belmeny-text"><i>Supervisor - {user.Nombre} - {user.CodSupervisor}</i></h5>
                <div className="container d-md-block d-none mb-3 bg-white mt-5 rounded drop-shadow-card">
                  <div className="row">
                    <div className="col text-center mx-auto">
                      <h4 className="text-center fw-bold py-2">Por favor ingrese todos los datos para crear el nuevo vídeo:</h4>
                      <form action="" className='mb-4 w-75 m-auto' onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                          <label htmlFor="title" className='mb-2 fw-bold'>Titulo del vídeo:</label>
                          <input type="text"
                            name="title"
                            id="title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="description" className='mb-2 fw-bold'>Descripción del vídeo:</label>
                          <input type="text"
                            name="description"
                            id="description"
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="link" className='mb-2 fw-bold'>Enlace del vídeo (Ej: https://www.youtube.com/watch?v=mkggXE5e2yk)</label>
                          <input type="text"
                            name="link"
                            id="link"
                            className="form-control"
                            value={link}
                            onChange={(e) => setLink(e.target.value)} />
                        </div>
                        <button className='btn btn-hover-login rounded-pill bg-belmeny text-light w-100 mt-3' type='submit'>Registrar nuevo vídeo <FontAwesomeIcon icon={faArrowAltCircleRight} /></button>
                        <div className={loadLogin ? 'login-load mt-3' : 'd-none'}>
                          <div className="m-auto spinner-login">
                          </div>
                          <h5 className='text-center spinner-login-text mt-2'>Cargando...</h5>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>
    </>
  )
}
