import React, { useState } from 'react'
import jsPDF from 'jspdf'

import AuthUser from '../../components/AuthUser';
import { getClienteRIF, getClientesByVendedorAndRif } from '../../api/requestClientes';
import { getDetallesCobranzas } from '../../api/requestCobranzas';
import { RiPrinterFill } from "react-icons/ri";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { belmenyJpg, ingcoJpg, vertJpg, fleximaticJpg, quilosaJpg, imouJpg } from '../../assets/img'
import brokenImg from '../../assets/img/broken-image-general.png';

export const CobranzaPDF = (props) => {

  const [disableBtn, setDisableBtn] = useState(false)

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  var Usuario = props.user
  var Cobranzas = props.Cobranzas

  var CodigoCliente, Documento, Fecha, TotalFinalCobranza, TasadelDia

  CodigoCliente = Cobranzas.CodCliente
  Documento = Cobranzas.Documento
  Fecha = new Date(Cobranzas.FechaCobranza.split(" ")[0]).toLocaleDateString('es-MX')
  TotalFinalCobranza = 0
  TasadelDia = 0

  const generatePDF = async () => {
    var doc = new jsPDF();

    var Cliente;

    if (Usuario.CodSupervisor === null) {
      Cliente = await getClienteRIF(CodigoCliente)
    } else {
      Cliente = await getClientesByVendedorAndRif(Usuario.CodVendedor, CodigoCliente)
    }

    //Cabecera
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(18)
    doc.text("Belmeny Group, C.A", 10, 20);
    doc.setFontSize(10)
    doc.text("R.I.F.: J-412333739 / N.I.T.: -", 10, 25);
    doc.text("LA LIMPIA MARACAIBO, EDO - ZULIA. TLF: 0261-7593849. FAX: -", 10, 30);
    doc.addImage(belmenyJpg, "PNG", 156, 10, 40, 30);
    doc.setFontSize(14).setTextColor("#002975").text("" + Documento, 165, 47).setFontSize(10).setTextColor("#000");

    //PARTE DE PEDIDO
    doc.setFontSize(18)
    doc.setLineWidth(0.5);

    doc.setFontSize(10)

    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(150, 50, 50, 30, 3, 3, "FD");

    doc.line(150, 55, 200, 55);
    doc.line(150, 65, 200, 65);
    doc.line(175, 50, 175, 80);
    doc.line(150, 70, 200, 70);

    doc.text("Emisión", 163, 54, null, null, "center");
    doc.text("" + Fecha, 162, 61, null, null, "center");

    doc.text("Vence", 187, 54, null, null, "center");
    doc.text("N/A", 187, 61, null, null, "center");

    doc.text("Cobranza", 163, 69, null, null, "center");
    doc.setFont(undefined, 'bold').setTextColor('#cb3837').text("" + Documento, 163, 76, null, null, "center").setFont(undefined, 'normal').setTextColor('#000');

    doc.text("Página", 187, 69, null, null, "center");
    doc.text("1", 187, 76, null, null, "center");

    doc.setFontSize(14)
    doc.text("Documento Afectado", 174, 90, null, null, "center");
    doc.setTextColor('#cb3837').text("" + Cobranzas.DocumentoAfectado, 175, 97, null, null, "center").setTextColor('#000');
    //FIN PARTE DE PEDIDO

    //INFORMACION CLIENTE
    doc.setFontSize(12)

    // Black square with rounded corners
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(10, 40, 130, 60, 3, 3, "FD");

    doc.line(10, 60, 140, 60);
    doc.line(10, 80, 140, 80);
    doc.line(10, 90, 140, 90);

    doc.setFontSize(10)
    doc.text("Cliente", 12, 45);
    doc.text("Cliente: R.I.F.:" + CodigoCliente + " \n" + Cliente[0].Nombre, 12, 45, {
      maxWidth: 120
    });

    doc.text("Domicilio fiscal", 12, 65)
    doc.text("Domicilio fiscal: " + Cliente[0].DireccionFiscal, 12, 65, {
      maxWidth: 130
    })

    doc.text("Teléfono", 12, 85)
    doc.text("Teléfono: " + Cliente[0].Telefono1, 12, 85)

    doc.text("Correo", 12, 95)
    doc.text("Correo: " + Cliente[0].Correo, 12, 95)
    //FIN INFORMACION CLIENTE

    //CONTENIDO
    doc.setFontSize(12)
    doc.setFontSize(9)
    doc.setLineWidth(1);
    doc.line(10, 105, 200, 105);
    doc.text("Referencia", 16, 110)
    doc.text("Método de Pago", 38, 110)
    doc.text("Banco", 75, 110)
    doc.text("Fecha", 105, 110)
    doc.text("Monto", 127, 110)
    doc.text("Recibo", 150, 110)
    doc.text("Tasa", 176, 110)
    doc.line(10, 112, 200, 112);

    const detalleCobranza = await getDetallesCobranzas(Documento);

    let textSpacing = 120
    var FechaPago = '';

    // Primera pagina
    for (let i = 0; i < detalleCobranza.data.length; i++) {

      FechaPago = new Date(detalleCobranza.data[i].FechaPago.split(" ")[0]).toLocaleDateString('es-MX')
      TotalFinalCobranza += detalleCobranza.data[i].MontoParcial

      doc.setFont(undefined, 'bold').setTextColor("#cb3837").text("" + detalleCobranza.data[i].DocumentoFormaPago, 15, textSpacing).setFont(undefined, 'normal').setTextColor("#000")

      if (detalleCobranza.data[i].FormaPago === 'tr') {
        doc.text("Transferencia", 40, textSpacing)
      } else {
        doc.text("Efectivo", 40, textSpacing)
      }
      doc.text(detalleCobranza.data[i].BancoPago, 65, textSpacing, {
        maxWidth: 70
      })
      doc.text(FechaPago, 102, textSpacing)
      doc.setFont(undefined, 'bold')
      doc.setTextColor("#198754")
      doc.setFont(undefined, 'bold').setTextColor("#198754").text((detalleCobranza.data[i].TasadelDia > 0 ? 'Bs. ' : '$') + detalleCobranza.data[i].MontoParcial.toFixed(2), 126, textSpacing).setTextColor("#cb3837")
      doc.text("" + detalleCobranza.data[i].Recibo, 148, textSpacing).setTextColor("#198754")
      doc.text("Bs. " + detalleCobranza.data[i].TasadelDia, 173, textSpacing).setFont(undefined, 'normal').setTextColor("#000")

      if (TasadelDia === 0 && detalleCobranza.data[i].TasadelDia > 0) {
        TasadelDia = detalleCobranza.data[i].TasadelDia
      }

      textSpacing += 20

      switch (i) {
        case 7:
          textSpacing = 25
          doc.addPage()
          doc.setPage(2)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 20:
          textSpacing = 25
          doc.addPage()
          doc.setPage(3)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 33:
          textSpacing = 25
          doc.addPage()
          doc.setPage(4)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 47:
          textSpacing = 25
          doc.addPage()
          doc.setPage(5)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 60:
          textSpacing = 25
          doc.addPage()
          doc.setPage(6)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 73:
          textSpacing = 25
          doc.addPage()
          doc.setPage(7)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 86:
          textSpacing = 25
          doc.addPage()
          doc.setPage(8)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 99:
          textSpacing = 25
          doc.addPage()
          doc.setPage(9)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 112:
          textSpacing = 25
          doc.addPage()
          doc.setPage(10)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 125:
          textSpacing = 25
          doc.addPage()
          doc.setPage(11)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 138:
          textSpacing = 25
          doc.addPage()
          doc.setPage(12)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 151:
          textSpacing = 25
          doc.addPage()
          doc.setPage(13)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 164:
          textSpacing = 25
          doc.addPage()
          doc.setPage(14)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 177:
          textSpacing = 25
          doc.addPage()
          doc.setPage(15)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 190:
          textSpacing = 25
          doc.addPage()
          doc.setPage(16)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 203:
          textSpacing = 25
          doc.addPage()
          doc.setPage(17)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 216:
          textSpacing = 25
          doc.addPage()
          doc.setPage(18)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 229:
          textSpacing = 25
          doc.addPage()
          doc.setPage(19)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 242:
          textSpacing = 25
          doc.addPage()
          doc.setPage(20)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 255:
          textSpacing = 25
          doc.addPage()
          doc.setPage(21)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 268:
          textSpacing = 25
          doc.addPage()
          doc.setPage(22)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 281:
          textSpacing = 25
          doc.addPage()
          doc.setPage(23)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 294:
          textSpacing = 25
          doc.addPage()
          doc.setPage(24)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 307:
          textSpacing = 25
          doc.addPage()
          doc.setPage(25)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 320:
          textSpacing = 25
          doc.addPage()
          doc.setPage(26)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 333:
          textSpacing = 25
          doc.addPage()
          doc.setPage(27)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 346:
          textSpacing = 25
          doc.addPage()
          doc.setPage(28)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 359:
          textSpacing = 25
          doc.addPage()
          doc.setPage(29)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 372:
          textSpacing = 25
          doc.addPage()
          doc.setPage(30)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 385:
          textSpacing = 25
          doc.addPage()
          doc.setPage(31)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 398:
          textSpacing = 25
          doc.addPage()
          doc.setPage(32)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 411:
          textSpacing = 25
          doc.addPage()
          doc.setPage(33)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 424:
          textSpacing = 25
          doc.addPage()
          doc.setPage(34)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 437:
          textSpacing = 25
          doc.addPage()
          doc.setPage(35)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 450:
          textSpacing = 25
          doc.addPage()
          doc.setPage(36)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 463:
          textSpacing = 25
          doc.addPage()
          doc.setPage(37)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 476:
          textSpacing = 25
          doc.addPage()
          doc.setPage(38)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 489:
          textSpacing = 25
          doc.addPage()
          doc.setPage(39)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 502:
          textSpacing = 25
          doc.addPage()
          doc.setPage(40)
          doc.line(10, 10, 200, 10);
          doc.text("Referencia", 16, 15)
          doc.text("Método de Pago", 38, 15)
          doc.text("Banco", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Monto", 127, 15)
          doc.text("Recibo", 150, 15)
          doc.text("Tasa", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        default:
          break;
      }
    }
    //FIN CONTENIDO

    //PIE DE PÁGINA
    doc.setLineWidth(0.5)
    doc.setFontSize(14)
    doc.rect(10, 260, 190, 20);

    if (TasadelDia > 0) {
      // doc.text("Total de la Cobranza: Bs.", 125, 277)
      doc.text("Total de la Cobranza: Bs." + TotalFinalCobranza.toFixed(2), 120, 277)
    } else {
      // doc.text("Total de la Cobranza: $", 125, 277)
      doc.text("Total de la Cobranza: $" + TotalFinalCobranza.toFixed(2), 120, 277)
    }

    // LOGOS
    doc.addImage(vertJpg, "JPG", 10, 283, 30, 12);
    doc.addImage(ingcoJpg, "JPG", 45, 283, 35, 10);
    doc.addImage(imouJpg, "JPG", 87, 281, 35, 12);
    doc.addImage(quilosaJpg, "JPG", 130, 283, 30, 12);
    doc.addImage(fleximaticJpg, "JPG", 167, 283, 33, 12);

    //FIN PIE DE PÁGINA

    var fileName = 'Cobranza ' + Documento + '.pdf'
    doc.save(fileName)
    setDisableBtn(false)
  }

  const loadGeneratePDF = () => {
    setDisableBtn(true)
    generatePDF()
    customSwal.fire({
      title: 'Generando archivo PDF',
      text: 'Espere un momento mientras se genera el PDF (puede tardar unos minutos por la longitud del presupuesto).',
      icon: 'info',
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true
    });
  }

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (disableBtn) ?
            <button className='btn btn-primary btn-sm mb-2 w-100 disabled' disabled>Generar Reporte</button>
            :
            <button onClick={loadGeneratePDF} className='btn btn-primary btn-sm mb-2 w-100'>Generar Reporte</button>
        }
      </div>

      <div className="d-sm-block d-md-none">
        {
          (disableBtn) ?
            <RiPrinterFill className="text-muted fs-2" />
            :
            <RiPrinterFill className="belmeny-text fs-2" onClick={loadGeneratePDF} />
        }
      </div>
    </>

  )
}
