import React, { useState } from 'react'

import AuthUser from '../../components/AuthUser';
import { getListaCadenasIngco } from '../../api/requestProductos';

const ExcelJS = require("exceljs")

export const ListaPrecioIngcoXLS = ({ fileName }) => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const [data, setData] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true)

  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  const exportExcelFile = async () => {
    setEnableBtn(false)
    const resProductos = await getListaCadenasIngco(user.CodVendedor,ZonasVenta);
    setData(resProductos)
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "FFFF0000" } },
      // left: { style: "thick", color: { argb: "000000FF" } },
      // bottom: { style: "thick", color: { argb: "F08080" } },
      // right: { style: "thick", color: { argb: "FF00FF00" } },
    };

    sheet.getRow(1).fill = {
      // type: "pattern",
      // pattern: "darkVertical",
      // fgColor: { argb: "FFFF00" },
    };

    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 16,
      bold: true,
    };

    sheet.columns = [
      {
        header: "Ruta imagen",
        key: "rutaImagen",
        width: 10,
      },
      {
        header: "Codigo",
        key: "codigo",
        width: 32,
      },
      {
        header: "Nombre",
        key: "nombre",
        width: 20,
      },
      {
        header: "Precio",
        key: "precio",
        width: 20,
      },
      {
        header: "Existencia",
        key: "existencia",
        width: 15,
      },
      {
        header: "Pedido Sugerido",
        key: "ventaMinima",
        width: 10,
      },
      {
        header: "Imagen",
        key: "imageId2",
        width: 30,
      },
      {
        header: "Codigo de Barras",
        key: "codigoBarras",
        width: 10,
      },
    ];

    const promise = Promise.all(
      resProductos.map(async (product, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          rutaImagen: product.RutaImagen,
          codigo: product.Codigo,
          nombre: product.Nombre,
          precio: product.Precio,
          existencia: product.Existencia,
          ventaMinima: product.VentaMinima,
          codigoBarras: product.CodigoBarras,
        });
        // console.log(product.RutaImagen);
        const result = await toDataURL(product.RutaImagen);
        const splitted = product.RutaImagen.split(".");
        const extName = splitted[splitted.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });

        sheet.addImage(imageId2, {
          tl: { col: 6, row: rowNumber },
          ext: { width: 120, height: 120 },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            // type: "pattern",
            // pattern: "solid",
            // fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Lista de Precio Cadenas INGCO.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
        setEnableBtn(true)
      });
    });
  };

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (enableBtn) ?
            <button onClick={exportExcelFile} className='btn btn-warning btn-sm mb-2 w-100'>Generar Lista de Precios INGCO</button>
            :
            <button className='btn btn-warning btn-sm mb-2 w-100 disabled' disabled>Generar Lista de Precios INGCO</button>
        }
      </div>
    </>
  )
}
