import React, { useState } from 'react'
import jsPDF from 'jspdf'

import AuthUser from '../../components/AuthUser';
import { getDetalleManifiesto } from '../../api/requestManifiesto';
import { RiPrinterFill } from "react-icons/ri";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import belmenyLogo from '../../assets/img/logo-png.webp';
import vertLogo from '../../assets/img/logo-vert.webp';
import ingcoLogo from '../../assets/img/logo-ingco.webp';
import imouLogo from '../../assets/img/logo-imou.webp';
import quilosaLogo from '../../assets/img/logo-quilosa.webp';
import fleximaticLogo from '../../assets/img/logo-fleximatic.webp';

export const ManifiestoPDF = (props) => {

  const [disableBtn, setDisableBtn] = useState(false)

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  var Usuario = props.user
  var Manifiesto = props.Manifiesto

  const generatePDF = async () => {
    var doc = new jsPDF();

    const manifiestoDocs = await getDetalleManifiesto(Manifiesto.DocumentoDetalle, Usuario.CodVendedor)

    var Documento = manifiestoDocs.data[0].DocumentoDetalle
    var FechaFacturacion = '';
    var Fecha = '';
    Fecha = new Date(Manifiesto.FechaSalida).toLocaleDateString('es-MX')
    FechaFacturacion = new Date(manifiestoDocs.data[0].FFacturacion).toLocaleDateString('es-MX')

    //Cabecera
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(18)
    doc.text("Belmeny Group, C.A", 10, 20);
    doc.setFontSize(10)
    doc.text("R.I.F.: J-412333739 / N.I.T.: -", 10, 25);
    doc.text("LA LIMPIA MARACAIBO, EDO - ZULIA. TLF: 0261-7593849. FAX: -", 10, 30);
    doc.addImage(belmenyLogo, "PNG", 166, 15, 20, 20);
    doc.setFontSize(18).setTextColor("#002975").text("Belmeny Group, C.A.", 145, 35).setFontSize(10).setTextColor("#000");

    //PARTE DE PEDIDO
    doc.setFontSize(18)
    doc.setLineWidth(0.5);

    doc.setFontSize(10)

    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(150, 40, 50, 30, 3, 3, "FD");

    doc.line(150, 45, 200, 45);
    doc.line(150, 55, 200, 55);
    doc.line(175, 40, 175, 70);
    doc.line(150, 60, 200, 60);

    doc.text("Emisión", 163, 44, null, null, "center");
    doc.text("Vence", 187, 44, null, null, "center");

    doc.text("Manifiesto", 163, 59, null, null, "center");
    doc.text("Página", 187, 59, null, null, "center");
    doc.text("" + Documento, 162.5, 66, null, null, "center");

    doc.text("" + Fecha, 163, 51, null, null, "center");

    doc.text("N/A", 187, 51, null, null, "center");
    doc.text("1", 187, 66, null, null, "center");

    doc.setFontSize(14)
    doc.text(Usuario.CodVendedor + " - " + Usuario.Nombre, 175, 78, null, null, "center");
    //FIN PARTE DE PEDIDO

    //INFORMACION CLIENTE
    doc.setFontSize(12)

    // Black square with rounded corners
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(10, 40, 130, 20, 3, 3, "FD");

    doc.line(10, 50, 140, 50);
    // doc.line(10, 60, 140, 60);
    // doc.line(10, 68, 140, 68);

    doc.setFontSize(10)
    doc.text("Vendedor", 12, 46);
    doc.text("Vendedor: " + Usuario.Nombre, 12, 46, {
      maxWidth: 120
    });

    doc.text("Código de Vendedor", 12, 56)
    doc.text("Código de Vendedor: " + Usuario.CodVendedor, 12, 56, {
      maxWidth: 130
    })
    //FIN INFORMACION CLIENTE

    //CONTENIDO
    doc.setFontSize(12)
    doc.setFontSize(9)
    doc.setLineWidth(1);
    doc.line(10, 81, 200, 81);
    doc.text("Pedido", 16, 85.5)
    doc.text("Factura", 38, 85.5)
    doc.text("Cliente", 70, 85.5)
    doc.text("Fecha", 105, 85.5)
    doc.text("Zona", 126, 85.5)
    doc.text("Cajas", 138, 85.5)
    doc.text("Bolsas", 150, 85.5)
    doc.text("Dirección Despacho", 165, 85.5)
    doc.line(10, 87, 200, 87);

    let textSpacing = 92

    // Primera pagina
    for (let i = 0; i < manifiestoDocs.data.length; i++) {

      doc.setFont(undefined, 'bold').setTextColor("#cb3837").text("" + manifiestoDocs.data[i].DocPedido.toUpperCase(), 15, textSpacing)
      doc.text("" + manifiestoDocs.data[i].DocFactura.toUpperCase(), 37, textSpacing).setFont(undefined, 'normal').setTextColor("#000")
      doc.text(manifiestoDocs.data[i].Cliente.toUpperCase(), 76, textSpacing, {
        maxWidth: 40,
        align: 'center'
      })
      doc.setFont(undefined, 'bold').setTextColor("#198754").text(FechaFacturacion, 102, textSpacing).setTextColor("#000")
      doc.setFont(undefined, 'normal').text("" + manifiestoDocs.data[i].SubZona, 123, textSpacing)
      doc.text("" + manifiestoDocs.data[i].Cajas, 142, textSpacing)
      doc.text("" + manifiestoDocs.data[i].Bolsas, 154, textSpacing)
      if (manifiestoDocs.data[i].DireccionDespacho === null) {
        doc.text("No se agregó una dirección", 180, textSpacing, {
          maxWidth: 30,
          align: 'center'
        })
      }
      else {
        doc.text(manifiestoDocs.data[i].DireccionDespacho, 180, textSpacing, {
          maxWidth: 30,
          align: 'center'
        })
      }

      textSpacing += 30

      switch (i) {
        case 6:
          textSpacing = 30
          doc.addPage()
          doc.setPage(2)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 14:
          textSpacing = 30
          doc.addPage()
          doc.setPage(3)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 22:
          textSpacing = 30
          doc.addPage()
          doc.setPage(4)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 30:
          textSpacing = 30
          doc.addPage()
          doc.setPage(5)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 38:
          textSpacing = 30
          doc.addPage()
          doc.setPage(6)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 46:
          textSpacing = 30
          doc.addPage()
          doc.setPage(7)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 54:
          textSpacing = 30
          doc.addPage()
          doc.setPage(8)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 62:
          textSpacing = 25
          doc.addPage()
          doc.setPage(9)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 70:
          textSpacing = 25
          doc.addPage()
          doc.setPage(10)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 78:
          textSpacing = 25
          doc.addPage()
          doc.setPage(11)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 86:
          textSpacing = 25
          doc.addPage()
          doc.setPage(12)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 94:
          textSpacing = 25
          doc.addPage()
          doc.setPage(13)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 102:
          textSpacing = 25
          doc.addPage()
          doc.setPage(14)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 110:
          textSpacing = 25
          doc.addPage()
          doc.setPage(15)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 118:
          textSpacing = 25
          doc.addPage()
          doc.setPage(16)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 126:
          textSpacing = 25
          doc.addPage()
          doc.setPage(17)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 134:
          textSpacing = 25
          doc.addPage()
          doc.setPage(18)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 142:
          textSpacing = 25
          doc.addPage()
          doc.setPage(19)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 150:
          textSpacing = 25
          doc.addPage()
          doc.setPage(20)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 158:
          textSpacing = 25
          doc.addPage()
          doc.setPage(21)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 166:
          textSpacing = 25
          doc.addPage()
          doc.setPage(22)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 174:
          textSpacing = 25
          doc.addPage()
          doc.setPage(23)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 182:
          textSpacing = 25
          doc.addPage()
          doc.setPage(24)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 190:
          textSpacing = 25
          doc.addPage()
          doc.setPage(25)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 198:
          textSpacing = 25
          doc.addPage()
          doc.setPage(26)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 206:
          textSpacing = 25
          doc.addPage()
          doc.setPage(27)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 214:
          textSpacing = 25
          doc.addPage()
          doc.setPage(28)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 222:
          textSpacing = 25
          doc.addPage()
          doc.setPage(29)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 230:
          textSpacing = 25
          doc.addPage()
          doc.setPage(30)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 238:
          textSpacing = 25
          doc.addPage()
          doc.setPage(31)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 246:
          textSpacing = 25
          doc.addPage()
          doc.setPage(32)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 254:
          textSpacing = 25
          doc.addPage()
          doc.setPage(33)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 262:
          textSpacing = 25
          doc.addPage()
          doc.setPage(34)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 270:
          textSpacing = 25
          doc.addPage()
          doc.setPage(35)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 278:
          textSpacing = 25
          doc.addPage()
          doc.setPage(36)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 286:
          textSpacing = 25
          doc.addPage()
          doc.setPage(37)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 294:
          textSpacing = 25
          doc.addPage()
          doc.setPage(38)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 127, 15)
          doc.text("Sub Zona", 150, 15)
          doc.text("Cajas", 176, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 302:
          textSpacing = 25
          doc.addPage()
          doc.setPage(39)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        case 310:
          textSpacing = 25
          doc.addPage()
          doc.setPage(40)
          doc.line(10, 10, 200, 10);
          doc.text("Pedido", 16, 15)
          doc.text("Factura", 38, 15)
          doc.text("Cliente", 75, 15)
          doc.text("Fecha", 105, 15)
          doc.text("Zona", 126, 15)
          doc.text("Cajas", 138, 15)
          doc.text("Bolsas", 150, 15)
          doc.text("Dirección Despacho", 165, 15)
          doc.line(10, 17, 200, 17);
          break;

        default:
          break;
      }
    }
    //FIN CONTENIDO

    // LOGOS
    doc.addImage(vertLogo, "PNG", 10, 281, 30, 12);
    doc.addImage(ingcoLogo, "PNG", 45, 283, 35, 10);
    doc.addImage(imouLogo, "PNG", 90, 283, 35, 10);
    doc.addImage(quilosaLogo, "PNG", 135, 282, 30, 12);
    doc.addImage(fleximaticLogo, "PNG", 170, 283, 30, 12);

    //FIN PIE DE PÁGINA


    var fileName = 'Manifiesto ' + Documento + '.pdf'
    doc.save(fileName)
    setDisableBtn(false)
  }

  const loadGeneratePDF = () => {
    setDisableBtn(true)
    generatePDF()
    customSwal.fire({
      title: 'Generando archivo PDF',
      text: 'Espere un momento mientras se genera el PDF (puede tardar unos minutos por la longitud del presupuesto).',
      icon: 'info',
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true
    });
  }

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (disableBtn) ?
            <button className='btn btn-primary btn-sm mb-2 w-100 disabled' disabled>Generar Reporte</button>
            :
            <button onClick={loadGeneratePDF} className='btn btn-primary btn-sm mb-2 w-100'>Generar Reporte</button>
        }
      </div>

      <div className="d-sm-block d-md-none">
        {
          (disableBtn) ?
            <RiPrinterFill className="text-muted fs-2" />
            :
            <RiPrinterFill className="belmeny-text fs-2" onClick={loadGeneratePDF} />
        }
      </div>
    </>

  )
}
