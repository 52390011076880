import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { AuthUser } from '../../../../components/AuthUser';
import { DetallePedido } from './DetallePedido';

import { RequestPage, getVendedor } from '../../../../api/request';
import { Pedidos } from '../../../../api/requestPedidos';

import Pagination from 'react-responsive-pagination';

export const ConsultaPedidosVendedor = () => {

  const params = useParams()

  // State del usuario
  const { user } = AuthUser();

  // States para los datos
  const [Pedido, setPedido] = useState([]);
  const [Vendedor, setVendedor] = useState();

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de busqueda
  const [term, setTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('')
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [inicio, setInicio] = useState('');
  const [fin, setFin] = useState('');

  const loadPedidos = async () => {
    setLoading(true)
    const res = await Pedidos(params.codigo);
    const vendorInfo = await getVendedor(params.codigo)
    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setUrlPage(res.links.first)

    setPedido(res.data)
    setVendedor(vendorInfo)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const pedidoData = await RequestPage(pageData);

    if (searchTerm) {
      setPage(pedidoData.current_page)
      setTotalPages(pedidoData.last_page)
      setUrlPage(pedidoData.first_page_url)
    } else {
      setPage(pedidoData.meta.current_page)
      setTotalPages(pedidoData.meta.last_page)
      setUrlPage(pedidoData.links.first)
    }

    setPedido(pedidoData.data)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (searchTerm) {
      switch (searchTerm) {
        case 'Fecha':
          loadPage(urlPage.replace(/.$/, page + `&CodUsuario=${params.codigo}&fechaInicio=${inicio}&fechaFin=${fin}&whatToSearch=${searchTerm}`))
          break;

        default:
          loadPage(urlPage.replace(/.$/, page + `&CodUsuario=${params.codigo}&Busqueda=${term}&whatToSearch=${searchTerm}`))
          break;
      }
    } else {
      loadPage(urlPage.replace(/.$/, page))
    }
  }

  useEffect(() => {
    loadPedidos();
  }, []);

  return (
    <>
      <Layout>
        {/* Cuerpo de la página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-documentos"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Pedidos</h4>
              </div>

              <h5 className="text-center mt-2 mb-4 belmeny-text">
                <i>
                  {
                    (user.CodGerente !== null) ?
                      <span>Supervisor / Gerente - {user.Nombre} - {user.CodVendedor}</span> :
                      <span>Vendedor - {user.Nombre} - {user.CodVendedor}</span>
                  }
                </i>
              </h5>

              {/* Desktop version */}
              <div className="container-fluid d-md-block d-none mb-3">
                <div className="rounded-0 my-2">
                  {
                    (notFound) ?
                      <>
                        <div className="alert alert-danger text-center" role="alert">
                          <h5>No se encontraron resultados</h5>
                        </div>
                      </> :
                      <>
                        <table className='table table-bordered table-hover border-belmeny'>
                          <thead className='bg-belmeny text-light text-center'>
                            <tr>
                              <th>Pedido</th>
                              <th className='w-50'>Cliente</th>
                              {(user.CodGerente !== null) ? <><th>Vendedor</th></> : null}
                              <th>Monto</th>
                              <th>Fecha</th>
                              <th style={{ width: "15%" }}>Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(Pedido.map((item) => <DetallePedido item={item} vendedor={Vendedor[0].Nombre} key={item.Documento} />))}
                          </tbody>
                        </table>

                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      </>
                  }
                </div>
              </div>

              {/* Mobile version */}
              <div className="container-fluid d-block d-sm-none">
                {
                  (notFound) ?
                    <>
                      <div className="alert alert-danger text-center" role="alert">
                        <h5>No se encontraron resultados</h5>
                      </div>
                    </> :
                    <>
                      {(Pedido.map((item) => <DetallePedido item={item} vendedor={Vendedor[0].Nombre} key={item.Documento + "Mobile"} />))}

                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
                <div className="division bg-belmeny my-3"></div>
              </div>
            </>
        }
      </Layout>
    </>
  )
}