import React, { useState } from 'react';
import { Button, Nav, Navbar, NavDropdown, Container, Form, Offcanvas, Dropdown, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { AuthUser } from './AuthUser';
import { menuDataVendedor, menuDataSupervisor, menuDataVideo, menuDataGerente } from '../data/menuData';

import belmenyLogo from '../assets/img/logo-belmeny-group2.png';
import belmenyLogo2 from '../assets/img/logoBG.webp';
import { FaBars, FaUser } from 'react-icons/fa';
import { MdHome } from "react-icons/md";

import "../assets/css/navbar.css"

export const NavbarComponent = (props) => {

  var bgHeaderApplied = '';
  var bgBtnApplied = '';

  switch (props.background) {
    case 'vert':
      bgHeaderApplied = 'bg-header';
      bgBtnApplied = 'btn-hover';
      break;

    case 'ingco':
      bgHeaderApplied = 'bg-headerIngco';
      bgBtnApplied = 'btn-hoverIngco';
      break;

    case 'wadfow':
      bgHeaderApplied = 'bg-headerWadfow';
      bgBtnApplied = 'btn-hoverWadfow';
      break;

    case 'corona':
      bgHeaderApplied = 'bg-headerCorona';
      bgBtnApplied = 'btn-hoverCorona';
      break;

    case 'fleximatic':
      bgHeaderApplied = 'bg-headerFleximatic';
      bgBtnApplied = 'btn-hoverFleximatic';
      break;

    case 'quilosa':
      bgHeaderApplied = 'bg-headerQuilosa';
      bgBtnApplied = 'btn-hoverQuilosa';
      break;

    case 'imou':
      bgHeaderApplied = 'bg-headerImou';
      bgBtnApplied = 'btn-hoverImou';
      break;


    default:
      bgHeaderApplied = 'bg-header';
      bgBtnApplied = 'btn-hover';
      break;
  }

  const navigate = useNavigate();
  const { getToken } = AuthUser();

  const { token, logout, user } = AuthUser();

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
      navigate('/login');
    }
  }

  var authButton, dashboardButton, catalogueButton, modules;

  if (!getToken()) {
    dashboardButton = <Link to="/" className={`text-decoration-none btn ${bgBtnApplied} border-0`}><MdHome /></Link>
    catalogueButton = <Link to="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Catálogo Productos</Link>
    authButton = <Link to="/login" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Iniciar sesión</Link>
  } else {
    if (user.CodVendedor !== null && user.CodSupervisor !== null) {
      dashboardButton = <Link to="/dashboard" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Inicio</Link>
    } else if (user.CodSupervisor === 'CONSULTA') {
      dashboardButton = <Link to="/consulta-productos" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Inicio</Link>
    } else if (user.CodSupervisor === null && user.CodGerente !== null) {
      dashboardButton = <Link to="/dashboard-supervisor" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Inicio</Link>
    } else if (user.CodGerente === 'master') {
      dashboardButton = <Link to="/register" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Inicio</Link>
    } else if (user.CodGerente === 'HABOULMOUNA') {
      dashboardButton = <Link to="/dashboard-gerente" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Inicio</Link>
      catalogueButton = <Link to="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Catálogo Productos</Link>
    }
    authButton = <Link onClick={logoutUser} to="/" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Cerrar sesión</Link>
  }

  if (user.CodVendedor !== null && user.CodSupervisor !== null) {
    // VENDEDOR
    modules = menuDataVendedor
  } else if (user.CodVendedor !== null && user.CodGerente !== null) {
    // SUPERVISOR / GERENTE
    modules = menuDataSupervisor
  } else if (user.CodSupervisor === 'SSAAB') {
    // SAMARA
    modules = menuDataVideo
  } else if (user.CodVendedor === null && user.CodSupervisor === null && user.CodGerente !== null) {
    // GERENTE
    modules = menuDataGerente
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-belmeny3">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img src={belmenyLogo2} alt="Belmeny Logo" className='text-center drop-shadow-sm align-center ms-4 w-25' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-white'/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            { (user.CodSupervisor !== "SSAAB") ? <Nav.Link>{dashboardButton}</Nav.Link> : null }
            {
              (user.CodVendedor === 'V138' || user.CodVendedor === 'V67') ?
                <Nav.Link>
                  <Link to="/price-list" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Lista de Precios</Link>
                </Nav.Link> : null
            }
            <NavDropdown title={
              <Link className={`text-decoration-none btn ${bgBtnApplied} border-0`}>
                Módulos
              </Link>
            } id="mega-dropdown" className="me-3">
              <div className="p-2 dropdown-menu-inner">
                {modules}
              </div>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link>{authButton}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
