import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';

import { getVideos, registerOrUpdateViewer } from '../../../api/request';

// Player
import ReactPlayer from 'react-player/youtube'

import { Badge } from 'react-bootstrap';

// Moment.js for date uses
import moment from 'moment/moment';

export const Tutorial = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(false);

  // State de datos
  const [videoList, setVideoList] = useState([])
  const [videoId, setVideoId] = useState(0)

  // Date
  const [today, setToday] = useState(moment().format("D/M/YYYY"));

  const loadVideos = async () => {
    setLoading(true)

    const resVideos = await getVideos()
    setVideoList(resVideos.data)
    setLoading(false)
  }

  const startVideo = (videoId) => {
    const registeredView = registerOrUpdateViewer(user.Usuario, videoId, "Start")
  }

  const endVideo = (videoId) => {
    const registeredView = registerOrUpdateViewer(user.Usuario, videoId, "End")
  }

  useEffect(() => {
    loadVideos()
  }, [])

  return (
    <>
      <Layout>
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <div className="loader-book">
                  <div>
                    <ul>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <span className="text-dark">Cargando vídeos...</span>
                </div>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Vídeos Informativos</h4>
              </div>

              <h5 className="text-center mt-2 mb-4 belmeny-text"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h5>

              <div className="container-fluid d-md-block d-none mb-3">
                {
                  (videoList.length > 0) ?
                    videoList.map((video, idx) => (
                      idx % 2 === 0 ? (
                        <div className="row" key={idx}>
                          {/* First Video */}
                          <div className="col text-center mx-auto">
                            <h5 className="text-center fw-bold py-1">
                              {
                                (today === new Date(video.fecha_subida).toLocaleDateString()) ?
                                  <Badge bg="danger">Nuevo</Badge> : null
                              }
                            </h5>
                            <h4 className="text-center fw-bold pt-2 pb-1">{video.titulo}</h4>
                            <h6 className="pb-3">Fecha de subida: {video.fecha_subida}</h6>
                            <ReactPlayer
                              className="mx-auto border-primary rounded drop-shadow-video"
                              url={video.link}
                              onStart={() => startVideo(video.id)}
                              onEnded={() => endVideo(video.id)}
                              config={{
                                youtube: {
                                  playerVars: {
                                    controls: true,
                                  },
                                },
                              }}
                            />
                            <div className="container w-75">
                              <h5 className="pt-3 text-justify">
                                Descripción:{" "}
                                <span className="fw-normal">{video.descripcion}</span>
                              </h5>
                            </div>
                          </div>

                          {/* Second Video (if available) */}
                          {videoList[idx + 1] && (
                            <div className="col text-center mx-auto">
                              <h5 className="text-center fw-bold py-1">
                                {
                                  (today === new Date(videoList[idx + 1].fecha_subida).toLocaleDateString()) ?
                                    <Badge bg="danger">Nuevo</Badge> : null
                                }
                              </h5>
                              <h4 className="text-center fw-bold pt-2 pb-1">{videoList[idx + 1].titulo}</h4>
                              <h6 className="pb-3">Fecha de subida: {videoList[idx + 1].fecha_subida}</h6>
                              <ReactPlayer
                                className="mx-auto border-primary rounded drop-shadow-video"
                                url={videoList[idx + 1].link}
                                onStart={() => startVideo(video[idx + 1].id)}
                                onEnded={() => endVideo(video[idx + 1].id)}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      controls: true,
                                    },
                                  },
                                }}
                              />
                              <div className="container w-75">
                                <h5 className="pt-3 text-justify">
                                  Descripción:{" "}
                                  <span className="fw-normal">{videoList[idx + 1].descripcion}</span>
                                </h5>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null
                    )) :
                    <div className="row mt-5">
                      <div className="container w-75 mx-auto">
                        <h4 className="text-center alert alert-danger"><span className="fst-italic">No se han encontrado vídeos disponibles por el momento</span> <span className="fw-normal">🎥</span></h4>
                      </div>
                    </div>
                }
              </div>
            </>
        }
      </Layout>
    </>
  )
}
