import React, { useState, useEffect } from 'react'

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import { getBuzon, getUsuario, getMessage, actualizarLeidoVendedor, actualizarLeidoSupervisor, actualizarLeidoGerencia, AprovOrDenyRequest, RequestPage } from '../../api/request';

import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import exhibidorPizarra from '../../assets/img/ingco-pizarra.jpeg'
import exhibidor1Cuerpo from '../../assets/img/ingco-1-cuerpo.png'
import exhibidor2Cuerpos from '../../assets/img/ingco-2-cuerpos.png'
import exhibidor3Cuerpos from '../../assets/img/ingco-3-cuerpos.png'
import exhibidor4Cuerpos from '../../assets/img/ingco-4-cuerpos.png'
import exhibidor5Cuerpos from '../../assets/img/ingco-5-cuerpos.png'
import exhibidor6Cuerpos from '../../assets/img/ingco-6-cuerpos.png'

export const Buzon = () => {

  const { user } = AuthUser();

  // State del loading
  const [loading, setLoading] = useState(true);
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success me-3',
      cancelButton: 'btn btn-danger',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  // States de datos
  const [usuario, setUsuario] = useState('');
  const [buzon, setBuzon] = useState([]);
  const [message, setMessage] = useState([]);
  const [observacionSolicitud, setObservacionSolicitud] = useState('');

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  const [showMessage, setShowMessage] = useState(false);

  const loadBuzon = async () => {
    setLoading(true)
    const resBuzon = await getBuzon();
    setBuzon(resBuzon.data.data)
    // console.log(resBuzon.data)
    setPage(resBuzon.data.current_page)
    setNextPage(resBuzon.data.next_page_url)
    setPrevPage(resBuzon.data.links[0].url)
    setFirstPage(resBuzon.data.first_page_url)
    setLastPage(resBuzon.data.last_page_url)
    setTotalPages(resBuzon.data.last_page)
    setLoading(false)
  }

  const selectMessage = async (e, nro_solicitud) => {
    setLoading(true)
    const resMsg = await getMessage(nro_solicitud)
    setShowMessage(true)
    setMessage(resMsg.data)
    if (user.CodVendedor != null) {
      const updateMsg = await actualizarLeidoVendedor(nro_solicitud)
    }
    else if (user.CodSupervisor != null) {
      const updateMsg = await actualizarLeidoSupervisor(nro_solicitud)
    }
    else if (user.CodGerente != null) {
      const updateMsg = await actualizarLeidoGerencia(nro_solicitud)
    }
    const resBuzon = await getBuzon();
    setBuzon(resBuzon.data.data)
    setLoading(false)
  }

  const loadPage = async (e, pageData) => {
    setLoading(true)

    const buzonData = await RequestPage(pageData);
    console.log(buzonData)

    setBuzon(buzonData.data)

    setPage(buzonData.current_page)
    setNextPage(buzonData.next_page_url)
    setPrevPage(buzonData.links[0].url)
    setFirstPage(buzonData.first_page_url)
    setLastPage(buzonData.last_page_url)
    setTotalPages(buzonData.last_page)
    setLoading(false)
  }

  const AprovOrDeny = async (e, typeUser, decision, nro_solicitud) => {
    e.preventDefault();
    if (decision == 'Aprobado' && typeUser == 'supervisor') {

      customSwal.fire({
        title: 'Aprobar Solicitud',
        html: <p>¿Está seguro de esta acción? <strong>(¡Es irreversible!)</strong></p>,
        icon: 'question',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await AprovOrDenyRequest(nro_solicitud, typeUser, decision, user.Nombre).then((response) => {
            console.log(response)
            if (response.status == 200) {
              setTimeout(() => {
                window.location.reload(false);
              }, 1000);
            }
          })
          // Se acepta el modal
          customSwal.fire(
            '¡Solicitud Aprobada!',
            'La solicitud ha sido aprobada con éxito.',
            'success',
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Se cancela o se sale del modal
          customSwal.fire(
            'Acción cancelada',
            'No se ha producido ningún cambio en la solicitud...',
            'error'
          )
        }
      })
    }
    else if (decision == 'Denegado' && typeUser == 'supervisor') {
      const { value: observacion } = await customSwal.fire({
        title: 'Denegar Solicitud',
        input: 'text',
        html: <p>Para denegar una solicitud debes agregar una observación. <strong>(Está acción será irreversible)</strong></p>,
        showCancelButton: true,
        icon: 'question',
        inputValidator: (value) => {
          if (!value) {
            return '¡Debes agregar una observación para continuar!'
          }
        }
      })

      if (observacion) {
        customSwal.fire({
          title: 'Denegar Solicitud',
          html: <p>¿Está seguro de esta acción? <strong>(¡Es irreversible!)</strong></p>,
          icon: 'question',
          confirmButtonText: 'Si',
          showCancelButton: true,
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await AprovOrDenyRequest(nro_solicitud, typeUser, decision, user.Nombre, observacion).then((response) => {
              console.log(response)
              if (response.status == 200) {
                setTimeout(() => {
                  window.location.reload(false);
                }, 1000);
              }
            })
            // Se confirma el modal
            customSwal.fire(
              '¡Solicitud Denegada!',
              'La solicitud ha sido denegada con éxito.',
              'success',
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Se cancela o se sale del modal
            customSwal.fire(
              'Acción cancelada',
              'No se ha producido ningún cambio en la solicitud...',
              'error'
            )
          }
        })
      }
    }
    else if (decision == 'Aprobado' && typeUser == 'gerente') {

      customSwal.fire({
        title: 'Aprobar Solicitud',
        html: <p>¿Está seguro de esta acción? <strong>(¡Es irreversible!)</strong></p>,
        icon: 'question',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await AprovOrDenyRequest(nro_solicitud, typeUser, decision, user.Nombre).then((response) => {
            console.log(response)
            if (response.status == 200) {
              setTimeout(() => {
                window.location.reload(false);
              }, 1000);
            }
          })
          // Se acepta el modal
          customSwal.fire(
            '¡Solicitud Aprobada!',
            'La solicitud ha sido aprobada con éxito.',
            'success',
          )
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Se cancela o se sale del modal
          customSwal.fire(
            'Acción cancelada',
            'No se ha producido ningún cambio en la solicitud...',
            'error'
          )
        }
      })
    }
    else if (decision == 'Denegado' && typeUser == 'gerente') {
      const { value: observacion } = await customSwal.fire({
        title: 'Denegar Solicitud',
        input: 'text',
        html: <p>Para denegar una solicitud debes agregar una observación. <strong>(Está acción será irreversible)</strong></p>,
        showCancelButton: true,
        icon: 'question',
        inputValidator: (value) => {
          if (!value) {
            return '¡Debes agregar una observación para continuar!'
          }
        }
      })

      if (observacion) {
        customSwal.fire({
          title: 'Denegar Solicitud',
          html: <p>¿Está seguro de esta acción? <strong>(¡Es irreversible!)</strong></p>,
          icon: 'question',
          confirmButtonText: 'Si',
          showCancelButton: true,
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await AprovOrDenyRequest(nro_solicitud, typeUser, decision, user.Nombre, observacion).then((response) => {
              console.log(response)
              if (response.status == 200) {
                setTimeout(() => {
                  window.location.reload(false);
                }, 1000);
              }
            })
            // Se confirma el modal
            customSwal.fire(
              '¡Solicitud Denegada!',
              'La solicitud ha sido denegada con éxito.',
              'success',
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Se cancela o se sale del modal
            customSwal.fire(
              'Acción cancelada',
              'No se ha producido ningún cambio en la solicitud...',
              'error'
            )
          }
        })
      }
    }
  }

  useEffect(() => {
    loadBuzon();
  }, []);

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div className="text-center mx-auto position-absolute top-50 start-50">
                  <span className="loader"></span>
                </div>
              </> :
              <>

                {/* Dashboard for mobile version */}
                <div className="d-sm-block d-md-none">
                  <div className="my-4">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Buzón de Solicitudes</h4>
                    </div>
                  </div>
                </div>

                {/* Dashboard for web version */}
                <div className='d-none d-md-block d-lg-block'>
                  <div className="container-fluid rounded">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Buzón de Solicitudes</h4>
                    </div>
                  </div>

                  <div className="row">
                    {/* Mensajes */}
                    <div className="col-4">
                      <div className="container">
                        {buzon.map((item) =>
                          // Vendedor
                          (item.usuario === user.Nombre) ?
                            <>
                              <div
                                className={(item.leido_vendedor === 0) ? "card mx-auto my-4 message shadow-drop-center bg-primary text-light" : "card mx-auto my-4 message shadow-drop-center"}
                                style={{ cursor: "pointer" }} onClick={(e) => selectMessage(e, item.nro_solicitud)}>
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col">
                                        <h6 className="text-start">{item.usuario}</h6>
                                      </div>
                                      <div className="col">
                                        <p className="text-end">{new Date(item.fecha_solicitud.split(" ")[0]).toLocaleDateString('es-MX', { timeZone: 'UTC' })}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-10">
                                      <p className="card-text">Solicitud de exhibidor para <strong>{item.cliente}</strong></p>
                                    </div>
                                    <div className="col-2">
                                      <p className={(item.leido_vendedor === 0) ? 'me-auto text-end fs-3 text-light' : 'me-auto text-end fs-3 belmeny-text'} >
                                        <BsFillArrowRightCircleFill />
                                      </p>
                                    </div>
                                  </div>
                                  {
                                    (item.leido_vendedor === 0) ?
                                      <>
                                        <span class="position-absolute top-0 start-95 translate-middle badge rounded-pill bg-danger">
                                          Nueva Solicitud
                                          <span class="visually-hidden">Nueva Solicitud</span>
                                        </span>
                                      </> :
                                      <>
                                      </>
                                  }
                                </div>
                              </div>
                            </> :
                            // Supervisor
                            (user.CodVendedor === null) && (user.CodSupervisor != null) ?
                              <>
                                <div
                                  className={(item.leido_supervisor === 0) ? "card mx-auto my-4 message shadow-drop-center bg-primary text-light" : "card mx-auto my-4 message shadow-drop-center"}
                                  style={{ cursor: "pointer" }} onClick={(e) => selectMessage(e, item.nro_solicitud)}>
                                  <div className="card-body">
                                    <div className="card-title">
                                      <div className="row">
                                        <div className="col">
                                          <h6 className="text-start">{item.usuario}</h6>
                                        </div>
                                        <div className="col">
                                          <p className="text-end">{new Date(item.fecha_solicitud.split(" ")[0]).toLocaleDateString('es-MX', { timeZone: 'UTC' })}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-10">
                                        <p className="card-text">Solicitud de exhibidor para <strong>{item.cliente}</strong></p>
                                      </div>
                                      <div className="col-2">
                                        <p className={(item.leido_supervisor === 0) ? 'me-auto text-end fs-3 text-light' : 'me-auto text-end fs-3 belmeny-text'} >
                                          <BsFillArrowRightCircleFill />
                                        </p>
                                      </div>
                                    </div>
                                    {
                                      (item.leido_supervisor === 0) ?
                                        <>
                                          <span class="position-absolute top-0 start-95 translate-middle badge rounded-pill bg-danger">
                                            Nueva Solicitud
                                            <span class="visually-hidden">Nueva Solicitud</span>
                                          </span>
                                        </> :
                                        <>
                                        </>
                                    }
                                  </div>
                                </div>
                              </> :
                              // Gerente
                              (user.CodSupervisor === null) && (user.CodGerente != null) ?
                                <>
                                  <div
                                    className={(item.leido_gerencia === 0) ? "card mx-auto my-4 message shadow-drop-center bg-primary text-light" : "card mx-auto my-4 message shadow-drop-center"}
                                    style={{ cursor: "pointer" }} onClick={(e) => selectMessage(e, item.nro_solicitud)}>
                                    <div className="card-body">
                                      <div className="card-title">
                                        <div className="row">
                                          <div className="col">
                                            <h6 className="text-start">{item.usuario}</h6>
                                          </div>
                                          <div className="col">
                                            <p className="text-end">{new Date(item.fecha_solicitud.split(" ")[0]).toLocaleDateString('es-MX', { timeZone: 'UTC' })}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-10">
                                          <p className="card-text">Solicitud de exhibidor para <strong>{item.cliente}</strong></p>
                                        </div>
                                        <div className="col-2">
                                          <p className={(item.leido_gerencia === 0) ? 'me-auto text-end fs-3 text-light' : 'me-auto text-end fs-3 belmeny-text'} >
                                            <BsFillArrowRightCircleFill />
                                          </p>
                                        </div>
                                      </div>
                                      {
                                        (item.leido_gerencia === 0) ?
                                          <>
                                            <span class="position-absolute top-0 start-95 translate-middle badge rounded-pill bg-danger">
                                              Nueva Solicitud
                                              <span class="visually-hidden">Nueva Solicitud</span>
                                            </span>
                                          </> :
                                          <>
                                          </>
                                      }
                                    </div>
                                  </div>
                                </> :
                                <>
                                </>
                        )}
                      </div>

                      {/* Paginación */}
                      <div className="mx-auto text-center">
                        <button onClick={e => loadPage(e, firstPage)} className='btn btn-sm btn-primary me-2'><AiOutlineDoubleLeft /></button>
                        {
                          (prevPage === null) ?
                            <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary" disabled>
                              <AiOutlineLeft />
                            </button> :
                            <button onClick={e => loadPage(e, prevPage)} className="btn btn-primary">
                              <AiOutlineLeft />
                            </button>
                        }
                        <span className="ms-2">{page}</span> de <span className="me-2">{totalPages}</span>
                        {
                          (nextPage === null) ?
                            <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" disabled>
                              <AiOutlineRight />
                            </button> :
                            <button onClick={e => loadPage(e, nextPage)} className="btn btn-primary" >
                              <AiOutlineRight />
                            </button>
                        }
                        <button onClick={e => loadPage(e, lastPage)} className="btn btn-sm btn-primary ms-2"><AiOutlineDoubleRight /></button>
                      </div>

                    </div>

                    {/* Cuerpo de mensajes */}
                    <div className="col-8 mb-5">
                      <div className="container bg-message shadow-drop-center p-3 rounded mt-4">
                        {
                          (showMessage) ?
                            <>
                              {message.map((item) =>
                                <>
                                  <div className="my-4">
                                    <h6 className='text-muted'>Solicitud #{item.nro_solicitud}</h6>
                                    <div className="division bg-belmeny my-2"></div>
                                    <h4 className="text-center">Solicitud de Exhibidor para <i>{item.cliente}</i></h4>
                                    <br />
                                    <div className="row">
                                      <div className="col">
                                        <p className='fs-6 text-start'>Solicitante: <b>{item.usuario}</b></p>
                                      </div>
                                      <div className="col">
                                        <p className="fs-6 text-end">Fecha de la solicitud: {new Date(item.fecha_solicitud.split(" ")[0]).toLocaleDateString('es-MX', { timeZone: 'UTC' })} {item.fecha_solicitud.split(" ")[1]}</p>
                                      </div>
                                    </div>
                                    <p className='fs-6'>Nro de Pedido relacionado: <b className='text-danger'>{item.nro_pedido}</b></p>

                                    <div className="row">
                                      <div className="col">
                                        <h4 className="mt-4">Motivo de la solicitud:</h4>
                                        <p>
                                          {item.motivo_solicitud}
                                        </p>
                                        <h4 className="mt-4">Datos del exhibidor:</h4>
                                        <p>Marca: <b>{item.marca}</b></p>
                                        <p>Tipo de Exhibidor: <b>{item.tipo_exhibidor}</b></p>
                                        <p>Monto del Exhibidor: <b className='text-success'>${item.monto_exhibidor}</b></p>
                                        {
                                          (item.tipo_exhibidor === 'Personalizado') ?
                                            <>
                                              <p>Material del Exhibidor: {item.material_exhibidor}</p>
                                              <p>Ancho del Exhibidor: {item.ancho}</p>
                                              <p>Alto del Exhibidor: {item.alto}</p>
                                            </> :
                                            <>
                                            </>
                                        }
                                        <div className="mt-3 mb-4">
                                          <h4>Estado de aprobación del exhibidor</h4>
                                          <p>Estado de aprobación del supervisor:
                                            {
                                              (item.aprobacion_supervisor === 'Aprobado') ?
                                                <>
                                                  🟢 {item.aprobacion_supervisor}
                                                </> :
                                                (item.aprobacion_supervisor === 'Denegado') ?
                                                  <>
                                                    🔴 {item.aprobacion_supervisor}
                                                  </>
                                                  :
                                                  <>
                                                    🔵 {item.aprobacion_supervisor}
                                                  </>
                                            }
                                          </p>
                                          <p>Estado de aprobación de la gerencia:
                                            {
                                              (item.aprobacion_gerencia === 'Aprobado') ?
                                                <>
                                                  🟢 {item.aprobacion_gerencia}
                                                </> :
                                                (item.aprobacion_gerencia === 'Denegado') ?
                                                  <>
                                                    🔴 {item.aprobacion_gerencia}
                                                  </>
                                                  :
                                                  <>
                                                    🔵 {item.aprobacion_gerencia}
                                                  </>
                                            }
                                          </p>
                                        </div>
                                        {
                                          (item.aprobacion_supervisor === 'Denegado') ?
                                            <>
                                              <h5>Observación del Supervisor:</h5>
                                              <p>{item.observacion_supervisor}</p>
                                            </> :
                                            (item.aprobacion_gerencia === 'Denegado') ?
                                              <>
                                                <h5 className='mt-2'>Observación de la Gerencia:</h5>
                                                <p>{item.observacion_gerencia}</p>
                                              </> :
                                              <>
                                              </>
                                        }
                                      </div>
                                      <div className="col">
                                        <div className="text-center">
                                          {
                                            (item.tipo_exhibidor === 'Pizarra') ?
                                              <>
                                                <img src={exhibidorPizarra} alt="Exhibidor de Pizarra" className='shadow-drop-center border-belmeny mt-2' />
                                                <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de Pizarra INGCO</legend>
                                              </> :
                                              (item.tipo_exhibidor === '1 Cuerpo') ?
                                                <>
                                                  <img src={exhibidor1Cuerpo} alt="Exhibidor de 1 Cuerpo" className='shadow-drop-center border-belmeny mt-2' />
                                                  <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 1 Cuerpo INGCO</legend>
                                                </> :
                                                (item.tipo_exhibidor === '2 Cuerpos') ?
                                                  <>
                                                    <img src={exhibidor2Cuerpos} alt="Exhibidor de 2 Cuerpos" className='shadow-drop-center border-belmeny mt-2' />
                                                    <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 2 Cuerpos INGCO</legend>
                                                  </> :
                                                  (item.tipo_exhibidor === '3 Cuerpos') ?
                                                    <>
                                                      <img src={exhibidor3Cuerpos} alt="Exhibidor de 3 Cuerpos" className='shadow-drop-center border-belmeny mt-2' />
                                                      <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 3 Cuerpos INGCO</legend>
                                                    </> :
                                                    (item.tipo_exhibidor === '4 Cuerpos') ?
                                                      <>
                                                        <img src={exhibidor4Cuerpos} alt="Exhibidor de 4 Cuerpos" className='shadow-drop-center border-belmeny mt-2' />
                                                        <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 4 Cuerpos INGCO</legend>
                                                      </> :
                                                      (item.tipo_exhibidor === '5 Cuerpos') ?
                                                        <>
                                                          <img src={exhibidor5Cuerpos} alt="Exhibidor de 5 Cuerpos" className='shadow-drop-center border-belmeny mt-2' />
                                                          <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 5 Cuerpos INGCO</legend>
                                                        </> :
                                                        (item.tipo_exhibidor === '6 Cuerpos') ?
                                                          <>
                                                            <img src={exhibidor6Cuerpos} alt="Exhibidor de 6 Cuerpos" className='shadow-drop-center border-belmeny mt-2' />
                                                            <legend className='mt-2 text-center text-muted fs-6'>Exhibidor de 6 Cuerpos INGCO</legend>
                                                          </> :
                                                          (item.tipo_exhibidor === 'Personalizado') ?
                                                            <>
                                                              <img src={exhibidorPizarra} alt="Exhibidor Personalizado" className='shadow-drop-center border-belmeny mt-2' />
                                                              <legend className='mt-2 text-center text-muted fs-6'>Exhibidor personalizado INGCO</legend>
                                                            </> :
                                                            <>
                                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    {/* Botones para denegar la solicitud */}
                                    {
                                      (item.aprobacion_supervisor != 'Denegado') && (item.aprobacion_gerencia != 'Denegado') ?
                                        <>
                                          <div className="row mt-3">
                                            <div className="col">
                                              {
                                                (user.CodSupervisor != null) ?
                                                  <>
                                                    <div className="row">
                                                      {
                                                        (item.aprobacion_supervisor === 'Aprobado') ?
                                                          <>
                                                            <button className="btn btn-lg btn-primary disabled w-75 mx-auto">Solicitud Aprobada</button>
                                                          </> :
                                                          <>
                                                            <div className="col text-end">
                                                              <button className="btn btn-lg btn-success" onClick={(e) => AprovOrDeny(e, 'supervisor', 'Aprobado', item.nro_solicitud)}>Aprobar Solicitud</button>
                                                            </div>
                                                            <div className="col text-start">
                                                              <button className="btn btn-lg btn-danger" onClick={(e) => AprovOrDeny(e, 'supervisor', 'Denegado', item.nro_solicitud)}>Denegar Solicitud</button>
                                                            </div>
                                                          </>
                                                      }
                                                    </div>
                                                  </> : (user.CodSupervisor === null) && (user.CodGerente != null) && (item.aprobacion_supervisor === 'Aprobado') ?
                                                    <>
                                                      <div className="row mx-auto">
                                                        {
                                                          (item.aprobacion_gerencia === 'Aprobado') ?
                                                            <>
                                                              <button className="btn btn-lg btn-primary disabled w-75 mx-auto">Solicitud Aprobada</button>
                                                            </> :
                                                            <>
                                                              <div className="col text-end">
                                                                <button className="btn btn-lg btn-success" onClick={(e) => AprovOrDeny(e, 'gerente', 'Aprobado', item.nro_solicitud)}>Aprobar Solicitud</button>
                                                              </div>
                                                              <div className="col text-start">
                                                                <button className="btn btn-lg btn-danger" onClick={(e) => AprovOrDeny(e, 'gerente', 'Denegado', item.nro_solicitud)}>Denegar Solicitud</button>
                                                              </div>
                                                            </>
                                                        }
                                                      </div>
                                                    </> :
                                                    <>
                                                    </>
                                              }
                                            </div>
                                          </div>
                                        </> :
                                        <>
                                        </>
                                    }
                                  </div>
                                </>
                              )}
                            </> :
                            <>
                              <div className="my-4">
                                <h2 className="belmeny-text text-center"><i>Para ver las solicitudes escoja un mensaje...</i></h2>
                              </div>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>
    </>
  )
}
